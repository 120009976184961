import * as actionTypes from './actionTypes';
import axios from "../../axios-instance.js";
import { showNotification } from './../../utils/notification';
const qs = require('qs')

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, email, role ) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        user: email,
        role: role
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    showNotification('Logged Out', 'error')
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(()=>{
            dispatch(logout());
            showNotification('Your session expired', 'error');
        }, expirationTime * 1000);
    };
};

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
                email: email,
                password: password
        };
        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(authData),
          }
          axios('/auth/login',options)
            .then(response => {
                if (response.status >=200 && response.status<300){
                    const expirationDate = new Date(new Date().getTime() + response.data.exp * 1000);
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('email', response.data.email);
                    localStorage.setItem('role', response.data.role);
                    Promise.all([dispatch(authSuccess(response.data.token, response.data.email, response.data.role)),dispatch(checkAuthTimeout(response.data.exp))])
                    .then(showNotification('Successfully logged in.'));
                };

            } )
            .catch( error=> {
                Promise.resolve(dispatch(authFail(error.response)))
                .then(showNotification(`${error.response.data.error}`,'error'));
            })
    };
};


export const authCheckState = (expirationDate) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout())
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
                showNotification('Your session expired', 'error')
            } else {
                const userId = localStorage.getItem('userId');
                const userType = localStorage.getItem('role')
                dispatch(authSuccess(token, userId, userType));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
            }
        }
    }
}