import styled from 'styled-components';
import {colours} from '../../../global-styles';

export const SideDrawer = styled.div`
    position: fixed;
    width: 100%;
    height: calc(100% - 60px);
    top: 60px;
    z-index: 400;
    background-color: ${colours.green01};
    box-sizing: border-box;
    transition: transform 0.2s ease-out;
    transform: ${props=>props.open ? 'translateX(0)' : 'translateX(-150%)'};
    overflow-y: auto;
    @media (min-width: 800px){ 
        display: none;
    }
`;

export const Logo = styled.p`
    height: 50px;
    border-bottom: 2px solid #A68E5F;
    font-size: 20px;
    font-weight: bold;
    
`;


