
//ARTWORKS

export const FETCH_PIECES = 'FETCH_PIECES';
export const FETCH_PIECES_FAILED = 'FETCH_PIECES_FAILED'
export const PIECE_POSTED = 'PIECE_POSTED';

export const FETCH_PIECES_START = 'FETCH_PIECES_START';

export const REGISTER_ARTWORK_SUCCESS = 'REGISTER_ARTWORK_SUCCESS';
export const REGISTER_ARTWORK_FAIL = 'REGISTER_ARTWORK_FAIL';
export const REGISTER_ARTWORK_START = 'REGISTER_ARTWORK_START';
export const REGISTER_HANDLE = 'REGISTER_HANDLE';

export const FETCH_ARTWORKS_DETAILS_START = 'FETCH_ARTWORKS_DETAILS_START';
export const FETCH_ARTWORKS_DETAILS_SUCCESS = 'FETCH_ARTWORKS_DETAILS_SUCCESS';
export const FETCH_ARTWORKS_DETAILS_FAIL = 'FETCH_ARTWORKS_DETAILS_FAIL';

export const AWS_PICS_SUCCESS = 'AWS_PICS_SUCCESS';
export const AWS_PICS_START = 'AWS_PICS_START';
export const AWS_PICS_FAIL = 'AWS_PICS_FAIL';

export const CLEAR_SELECTED_ARTWORK = 'CLEAR_SELECTED_ARTWORK';

export const AWS_THUMBS_SUCCESS = 'AWS_THUMBS_SUCCESS';
export const AWS_THUMBS_START = 'AWS_THUMBS_START';
export const AWS_THUMBS_FAIL = 'AWS_THUMBS_FAIL';
export const AWS_THUMBS_COMPLETE = 'AWS_THUMBS_COMPLETE';

export const EDIT_ARTWORK_NOTES_START = 'EDIT_ARTWORK_NOTES_START';
export const EDIT_ARTWORK_NOTES_FAIL = 'EDIT_ARTWORK_NOTES_FAIL';
export const EDIT_ARTWORK_NOTES_SUCCESS = 'EDIT_ARTWORK_NOTES_SUCCESS';

export const CLEAR_SELECTED_LIST = 'CLEAR_SELECTED_LIST';

export const PIECE_UNAVAILABLE_START = 'PIECE_UNAVAILABLE_START';
export const PIECE_UNAVAILABLE_SUCCESS = 'PIECE_UNAVAILABLE_SUCCESS';
export const PIECE_UNAVAILABLE_FAIL = 'PIECE_UNAVAILABLE_FAIL';

export const FETCH_PIECE_SUCCESS = 'FETCH_PIECE_SUCCESS';
export const FETCH_PIECE_START = 'FETCH_PIECE_START';
export const FETCH_PIECE_FAIL = 'FETCH_PIECE_FAIL';

export const CLEAR_MODAL_STATE = 'CLEAR_MODAL_STATE';

export const REMOVE_ITEM_DRAFT = 'REMOVE_ITEM_DRAFT';
export const REMOVE_ITEM_DRAFT_FAIL = 'REMOVE_ITEM_DRAFT_FAIL';
export const REMOVE_ITEM_DRAFT_SUCCESS = 'REMOVE_ITEM_DRAFT_SUCCESS';

export const SET_PICS_DATA =  'SET_PICS_DATA';

export const CLEAR_AWS_STATE = 'CLEAR_AWS_STATE';

export const LIST_MODAL_SELECTED = 'LIST_MODAL_SELECTED';

export const SELECTED_MODAL_GALLERY = 'SELECTED_MODAL_GALLERY';

export const EDIT_ARTWORK_INIT = 'EDIT_ARTWORK_INIT';
export const EDIT_ARTWORK_CANCEL = 'EDIT_ARTWORK_CANCEL';

export const EDIT_ARTWORK_START = 'EDIT_ARTWORK_START';
export const EDIT_ARTWORK_SUCCESS = 'EDIT_ARTWORK_SUCCESS';
export const EDIT_ARTWORK_FAIL = 'EDIT_ARTWORK_FAIL';

export const PIECE_DELETED_START = 'PIECE_DELETED_START';
export const PIECE_DELETED_SUCCESS = 'PIECE_DELETED_SUCCESS';
export const PIECE_DELETED_FAIL = 'PIECE_DELETED_FAIL';

export const PIECE_FOCUSED = 'PIECE_FOCUSED';
export const PIECE_DRAFTED = 'PIECE_DRAFTED';

export const PIECE_ADDED_TO_LIST = 'PIECE_ADDED_TO_LIST';
export const PIECE_REMOVED_FROM_LIST = 'PIECE_REMOVED_FROM_LIST';


//LISTS

export const FETCH_LISTS = 'FETCH_LISTS';
export const FETCH_LISTS_START = 'FETCH_LISTS_START';
export const FETCH_LISTS_FAILED = 'FETCH_LISTS_FAILED';
export const CREATE_LIST_START = 'CREATE_LIST_START';
export const CREATE_LIST_SUCCESS = 'CREATE_LIST_SUCCESS';
export const CREATE_LIST_FAIL = 'CREATE_LIST_FAIL';
export const DELETE_LIST_START = 'DELETE_LIST_START';
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS';
export const DELETE_LIST_FAIL = 'DELETE_LIST_FAIL';

export const FOCUSED_LIST = 'FOCUSED_LIST';

export const FETCH_CONSIGNMENTS = 'FETCH_CONSIGNMENTS';
export const FETCH_CONSIGNMENTS_START = 'FETCH_CONSIGNMENTS_START';
export const FETCH_CONSIGNMENTS_FAILED = 'FETCH_CONSIGNMENTS_FAILED'
export const CONSIGNMENT_POSTED = 'CONSIGNMENT_POSTED';
export const CONSIGNMENT_CLICKED = 'CONSIGNMENT_CLICKED';
export const FOCUSED_CONSIGNMENT = 'FOCUSED_CONSIGNMENT';
export const REGISTER_CONSIGNMENT_SUCCESS = 'REGISTER_CONSIGNMENT_SUCCESS';
export const REGISTER_CONSIGNMENT_FAIL = 'REGISTER_CONSIGNMENT_FAIL';
export const REGISTER_CONSIGNMENT_START = 'REGISTER_CONSIGNMENT_START';

export const MERGE_TO_CONSIGN_START = 'MERGE_TO_CONSIGN_START';
export const MERGE_TO_CONSIGN_SUCCESS ='MERGE_TO_CONSIGN_SUCCESS';
export const MERGE_TO_CONSIGN_FAIL = 'MERGE_TO_CONSIGN_FAIL';

export const REMOVE_ITEM_LIST_START = 'REMOVE_ITEM_LIST_START';
export const REMOVE_ITEM_LIST_SUCCESS = 'REMOVE_ITEM_LIST_SUCCESS';
export const REMOVE_ITEM_LIST_FAIL = 'REMOVE_ITEM_LIST_FAIL';

export const REMOVE_ITEM_CONSIGN_START = 'REMOVE_ITEM_CONSIGN_START';
export const REMOVE_ITEM_CONSIGN_SUCCESS = 'REMOVE_ITEM_CONSIGN_SUCCESS';
export const REMOVE_ITEM_CONSIGN_FAIL = 'REMOVE_ITEM_CONSIGN_FAIL';

export const SAVE_AS_CONSIGN_START = 'SAVE_AS_CONSIGN_START';
export const SAVE_AS_CONSIGN_SUCCESS = 'SAVE_AS_CONSIGN_SUCCESS';
export const SAVE_AS_CONSIGN_FAIL = 'SAVE_AS_CONSIGN_FAIL';

export const DELETE_CONSIGN_START = 'DELETE_CONSIGN_START';
export const DELETE_CONSIGN_SUCCESS = 'DELETE_CONSIGN_SUCCESS';
export const DELETE_CONSIGN_FAIL = 'DELETE_CONSIGN_FAIL';

export const EDIT_CONSIGN_START = 'EDIT_CONSIGN_START';
export const EDIT_CONSIGN_SUCCESS = 'EDIT_CONSIGN_SUCCESS';
export const EDIT_CONSIGN_FAIL = 'EDIT_CONSIGN_FAIL';

export const SAVE_AS_LIST_START = 'SAVE_AS_LIST_START';
export const SAVE_AS_LIST_FAIL = 'SAVE_AS_LIST_FAIL';
export const SAVE_AS_LIST_SUCCESS = 'SAVE_AS_LIST_SUCCESS';
export const CLEAR_DRAFT_LIST = 'CLEAR_DRAFT_LIST';

export const SAVE_TO_LIST_START = 'SAVE_TO_LIST_START';
export const SAVE_TO_LIST_SUCCESS = 'SAVE_TO_LIST_SUCCESS';
export const SAVE_TO_LIST_FAIL = 'SAVE_TO_LIST_FAIL';

export const PUSH_TO_CONSIGN_START = 'PUSH_TO_CONSIGN_START';
export const PUSH_TO_CONSIGN_SUCCESS = 'PUSH_TO_CONSIGN_SUCCESS';
export const PUSH_TO_CONSIGN_FAIL = 'PUSH_TO_CONSIGN_FAIL';

//GALLERIES

export const FETCH_GALLERIES = 'FETCH_GALLERIES';
export const FETCH_GALLERIES_START = 'FETCH_GALLERIES_START';
export const FETCH_GALLERIES_FAILED = 'FETCH_GALLERIES_FAILED';
export const GALLERY_EDITED = 'GALLERY_EDITED';
export const GALLERY_DELETED_START = 'GALLERY_DELETED_START';
export const GALLERY_DELETED_SUCCESS = 'GALLERY_DELETED_SUCCESS';
export const GALLERY_DELETED_FAIL = 'GALLERY_DELETED_FAIL';
export const REGISTER_GALLERY_START = 'REGISTER_GALLERY_START';
export const REGISTER_GALLERY_SUCCESS = 'REGISTER_GALLERY_SUCCESS';
export const REGISTER_GALLERY_FAIL = 'REGISTER_GALLERY_FAIL';

export const FETCH_GALLERY_ARTWORKS_START = 'FETCH_GALLERY_ARTWORKS_START';
export const FETCH_GALLERY_ARTWORKS_SUCCESS = 'FETCH_GALLERY_ARTWORKS_SUCCESS';
export const FETCH_GALLERY_ARTWORKS_FAIL = 'FETCH_GALLERY_ARTWORKS_FAIL';

export const EDIT_GALLERY_START = 'EDIT_GALLERY_START';
export const EDIT_GALLERY_SUCCESS = 'EDIT_GALLERY_SUCCESS';
export const EDIT_GALLERY_FAIL = 'EDIT_GALLERY_FAIL';

export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';

export const TOGGLE_EDIT = 'TOGGLE_EDIT';
export const MODAL_TOGGLE = 'MODAL_TOGGLE';

//AUTH

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH =  'SET_AUTH_REDIRECT_PATH';
export const PROV_AUTH =  'PROV_AUTH';

//EXHIBITIONS

export const FETCH_EXHIBITIONS_FAIL = 'FETCH_EXHIBITIONS_FAIL';
export const FETCH_EXHIBITIONS_SUCCESS = 'FETCH_EXHIBITIONS_SUCCESS';
export const FETCH_EXHIBITIONS_START = 'FETCH_EXHIBITIONS_START';

export const REMOVE_ARTWORK_EXHIBITION_START = 'REMOVE_ARTWORK_EXHIBITION_START';
export const REMOVE_ARTWORK_EXHIBITION_SUCCESS = 'REMOVE_ARTWORK_EXHIBITION_SUCCESS';
export const REMOVE_ARTWORK_EXHIBITION_FAIL = 'REMOVE_ARTWORK_EXHIBITION_FAIL';

export const NEW_EXHIBITION_START = 'NEW_EXHIBITION_START';
export const NEW_EXHIBITION_SUCCESS = 'NEW_EXHIBITION_SUCCESS';
export const NEW_EXHIBITION_FAIL = 'NEW_EXHIBITION_FAIL';

export const FETCH_SELECTED_EXHIBITION = 'FETCH_SELECTED_EXHIBITION';
export const SELECTED_EXHIBITION_DATA = 'SELECTED_EXHIBITION_DATA';

export const DELETE_EXHIBITION_SUCCESS = 'DELETE_EXHIBITION_SUCCESS';
export const DELETE_EXHIBITION_START = 'DELETE_EXHIBITION_START';
export const DELETE_EXHIBITION_FAIL = 'DELETE_EXHIBITION_FAIL';

export const ADD_PIECE_TO_EXHIBITION_START = 'ADD_PIECE_TO_EXHIBITION_START';
export const ADD_PIECE_TO_EXHIBITION_SUCCESS = 'ADD_PIECE_TO_EXHIBITION_SUCCESS';
export const ADD_PIECE_TO_EXHIBITION_FAIL = 'ADD_PIECE_TO_EXHIBITION_FAIL';

export const EDIT_PIECE_LAYOUT_EXHIBITION_START = 'EDIT_PIECE_LAYOUT_EXHIBITION_START';
export const EDIT_PIECE_LAYOUT_EXHIBITION_SUCCESS = 'EDIT_PIECE_LAYOUT_EXHIBITION_SUCCESS';
export const EDIT_PIECE_LAYOUT_EXHIBITION_FAIL = 'EDIT_PIECE_LAYOUT_EXHIBITION_FAIL';

export const REMOVE_ARTWORK_EXHIBIT_START = 'REMOVE_ARTWORK_EXHIBIT_START';
export const REMOVE_ARTWORK_EXHIBIT_SUCCESS= 'REMOVE_ARTWORK_EXHIBIT_SUCCESS';
export const REMOVE_ARTWORK_EXHIBIT_FAIL= 'REMOVE_ARTWORK_EXHIBIT_FAIL';

// SALES 

export const FETCH_CLIENTS_START = 'FETCH_CLIENTS_START' ;
export const FETCH_CLIENTS_SUCCESS= 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAIL= 'FETCH_CLIENTS_FAIL';
export const FETCH_CLIENTS_PIECES_START= 'FETCH_CLIENTS_PIECES_START';
export const FETCH_CLIENTS_PIECES_SUCCESS= 'FETCH_CLIENTS_PIECES_SUCCESS';
export const FETCH_CLIENTS_PIECES_FAIL= 'FETCH_CLIENTS_PIECES_FAIL';

//clients

export const REGISTER_CLIENT_START = 'REGISTER_CLIENT_START';
export const REGISTER_CLIENT_SUCCESS= 'REGISTER_CLIENT_SUCCESS';
export const REGISTER_CLIENT_FAIL = 'REGISTER_CLIENT_FAIL';

export const EDIT_CLIENT_START= 'EDIT_CLIENT_START';
export const EDIT_CLIENT_SUCCESS= 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_FAIL= 'EDIT_CLIENT_FAIL';

export const DELETE_CLIENT_START= 'DELETE_CLIENT_START';
export const DELETE_CLIENT_SUCCESS= 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAIL= 'DELETE_CLIENT_FAIL';


// TRANSACTION

export const FETCH_TRANSACTIONS_START = 'FETCH_TRANSACTIONS_START';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAIL = 'FETCH_TRANSACTIONS_FAIL';

export const CREATE_TRANSACTION_START = ' CREATE_TRANSACTION_START';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAIL = 'CREATE_TRANSACTION_FAIL';

export const EDIT_TRANSACTION_START = 'EDIT_TRANSACTION_START';
export const EDIT_TRANSACTION_SUCCESS = 'EDIT_TRANSACTION_SUCCESS';
export const EDIT_TRANSACTION_FAIL = 'EDIT_TRANSACTION_FAIL';

export const SELECT_TRANSACTION = 'SELECT_TRANSACTION';
export const REMOVE_TRANSACTION = 'REMOVE_TRANSACTION';

export const CLEAR_SELECTED_TRANSACTIONS = 'CLEAR_SELECTED_TRANSACTIONS';

export const DELETE_TRANSACTION_START = 'DELETE_TRANSACTION_START';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAIL ='DELETE_TRANSACTION_FAIL';
