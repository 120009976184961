import React, { Component } from 'react';
import { Switch, Route,Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions';
import pMinDelay from 'p-min-delay';
import Layout from './hoc/Layout/Layout';
import { GlobalStyle } from './global-styles.js';
import loadable from '@loadable/component';
import Modal from './components/UI/Modal/Modal';

const LandingPage = loadable(() => pMinDelay(import('./containers/LandingPage/LandingPage'),100), {
  fallback: <div style={{width: '100px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const ArtworksContainer = loadable(() => pMinDelay(import('./containers/Artworks/ArtworksContainer'),100), {
fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const ArtworkRegistration = loadable(() => pMinDelay(import('./containers/Artworks/NewArtwork'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const ConsignmentsContainer = loadable(() => pMinDelay(import('./containers/Consignments/Consignments'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const GalleriesContainer = loadable(() => pMinDelay(import('./containers/Galleries/Galleries'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const ListsContainer = loadable(() => pMinDelay(import('./containers/Lists/Lists'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const Auth = loadable(() => pMinDelay(import('./containers/Auth/Auth'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const Logout = loadable(() => pMinDelay(import('./containers/Auth/Logout/Logout'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const EditArtwork = loadable(() => pMinDelay(import('./containers/Artworks/EditArtwork'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const Exhibitions = loadable(() => pMinDelay(import('./containers/Exhibitions/Exhibitions.js'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const Artwork = loadable(() => pMinDelay(import('./containers/Artworks/Artwork'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const SalesContainer = loadable(() => pMinDelay(import('./containers/Sales'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const NewExhibition = loadable(() => pMinDelay(import('./containers/Exhibitions/NewExhibition/NewExhibition.js'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const Exhibition = loadable(() => pMinDelay(import('./containers/Exhibitions/Exhibition/Exhibition'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const NoMatch = loadable(() => pMinDelay(import('./containers/NoMatch'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});
const UserSettings = loadable(() => pMinDelay(import('./containers/Admin'),100), {
  fallback: <div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});

const SecondaryLocation = loadable(()=>pMinDelay(import('./containers/SecondaryLocation'),100),{
  fallback:<div style={{width: '200px', color: 'white', position: 'absolute', top: '200px', left: 'calc(50% - 100px)'}}> Loading... </div>
});

export class App extends Component {

  componentDidMount () {
    this.props.onTryAutoSignIn();
  }
  
  render() {
    const token = localStorage.getItem('token');
    const {
      isAuthenticated
    } = this.props;
    
    return (
      <React.Fragment>
        {this.props.modalOpen &&
          <Modal
            show={this.props.modalOpen}
            modalClosed={() => this.props.onToggleModal(false, null)}
          >
            {this.props.modalContent}
          </Modal>
        }
        <Layout route={this.props.location.pathname}>
            {(!token && !isAuthenticated) &&
              <Switch>
                <Route path="/login" component={Auth} />
                <Route path="*" >
                  <Redirect to="/login"/>
                </Route>
              </Switch>
            }
            {((token || isAuthenticated)) &&
              <Switch>
                <Route path="/home" component={LandingPage}/>
                <Route path="/artworks/register" exact component={ArtworkRegistration}/>
                <Route path="/artworks/edit/:id/" exact component={EditArtwork}/>
                <Route path="/artworks/:id/" exact component={Artwork}/>
                <Route exact path="/artworks" component={ArtworksContainer}/>
                <Route path="/lists" component={ListsContainer} />
                <Route path="/sales/clients" component={SalesContainer}/>
                <Route path="/sales/transactions" component={SalesContainer}/>
                <Route path="/sales" exact component={SalesContainer}>
                  <Redirect to="/sales/transactions" />
                </Route> 
                <Route path="/logout" component={Logout} />
                <Route path="/consignments" component={ConsignmentsContainer} />
                <Route path="/galleries" component={GalleriesContainer} />
                <Route path="/exhibitions/new-exhibit" component={NewExhibition}/>
                <Route path="/exhibitions/:id/" component={Exhibition} />
                <Route path="/exhibitions" exact component={Exhibitions} />
                <Route path="/users-admin" exact component={UserSettings}/>
                <Route path="/tagliapietra-branch" exact component={SecondaryLocation}>
                    <Redirect to="/tagliapietra-branch/artworks" />
                </Route>
                <Route path="/tagliapietra-branch/artworks" exact component={SecondaryLocation}/>
                <Route path="/tagliapietra-branch/lists" exact component={SecondaryLocation}/>
                <Route path="/tagliapietra-branch/consignments" exact component={SecondaryLocation}/>
                <Route path="/tagliapietra-branch/exhibitions" exact component={SecondaryLocation}/>
                <Route path="/tagliapietra-branch/galleries" exact component={SecondaryLocation}/>
                <Route path="/tagliapietra-branch/transactions" exact component={SecondaryLocation}/>
                <Route path="/tagliapietra-branch/clients" exact component={SecondaryLocation}/>
                <Route exact path="/">
                  <Redirect to='/home' component={LandingPage} onEnter={this.fetchData}/>
                </Route>
                <Route exact path="/login">
                  <Redirect to="/home"/>
                </Route>
                <Route path="*" component={NoMatch}/>
              </Switch> 
            }
            <GlobalStyle />
        </Layout>
      </React.Fragment> 
    );
  }
}

const mapStateToProps = state => {
  return {
    userRole: state.auth.userType,
    isAuthenticated: state.auth.token !== null,
    modalOpen: state.UI.modal.modalOpen,
    modalContent: state.UI.modal.modalContent
  };
};

const mapDispatchToprops = dispatch => {
  return {
    onToggleModal: (isOpen) => dispatch(actions.handleModal(isOpen,null)), 
    onTryAutoSignIn: () => dispatch( actions.authCheckState() ),
    onInitArtworks: () => dispatch(actions.initPieces()),
    onInitLists: () => dispatch(actions.initLists()),
    onInitGalleries: () => dispatch(actions.initGalleries()),
    onInitConsignments: () => dispatch(actions.initConsignments()),
    onInitExhibitions: () => dispatch(actions.initExhibitions()),
    onInitClients: () => dispatch(actions.initClients()),
  };
};

export default withRouter(connect( mapStateToProps, mapDispatchToprops)(App)) ;
