import React from 'react';
import NavigationItem from './NavigationItem/NavigationItem';
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import StoreRoundedIcon from '@material-ui/icons/StoreRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import ArtTrackRoundedIcon from '@material-ui/icons/ArtTrackRounded';
import WidgetsIcon from '@material-ui/icons/Widgets';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';

const NavigationItemsSt = styled.ul`
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    color: #A68D5F;
    &:active{
        color: #A68D5F;
    }
`;

const navigationItems = ( props ) => (
    <NavigationItemsSt>
        <NavigationItem 
            links={[{name: 'Home', link: "/home"}]} 
            exact 
            isOpen={props.toolbarOpen}
            clicked={props.clicked}
        >
            {props.toolbarOpen ? 'Home' 
            : 
            <Tooltip title="Home">
                <HomeOutlinedIcon/>
            </Tooltip>
            }
        </NavigationItem>
        <NavigationItem 
            links={[
                {name: 'All Artworks', link: '/artworks'},
                {name: 'Register', link: '/artworks/register'}
            ]}
            clicked={props.clicked}
            isOpen={props.toolbarOpen}
        >
            {props.toolbarOpen
                ? 'Artworks' 
                : 
                <Tooltip title="Artworks">
                    <WidgetsIcon/>
                </Tooltip>
            }
        </NavigationItem>
        <NavigationItem 
            links={[
                {name: 'All', link: "/lists"}
            ]}
            exact 
            clicked={props.clicked}
            isOpen={props.toolbarOpen}
        >
            {props.toolbarOpen
                ? 'Provisional Lists' 
                : 
                <Tooltip title="Lists">
                    <ListRoundedIcon/>
                </Tooltip>
            }
        </NavigationItem>
        <NavigationItem 
            links={[{name: 'Consignments', link: "/consignments"}]} 
            exact 
            isOpen={props.toolbarOpen}
            clicked={props.clicked}
        >
            {props.toolbarOpen
                ? 'Consignments'
                : 
                <Tooltip title="Consignments">
                    <LocalShippingRoundedIcon/>
                </Tooltip>
            }
        </NavigationItem>
        <NavigationItem 
            links={[{name: 'Galleries', link: "/galleries"}]} 
            exact 
            isOpen={props.toolbarOpen}
            clicked={props.clicked}
        >
            {props.toolbarOpen
            ? 'Galleries'
            : 
            <Tooltip title="Galleries">
                <StoreRoundedIcon/>
            </Tooltip>
            }
        </NavigationItem>
        <NavigationItem 
            links={[
                {name: 'All Exhibitions', link: "/exhibitions"},
                {name: 'New  Exhibition', link: "/exhibitions/new-exhibit"}
            ]}
            isOpen={props.toolbarOpen}
            clicked={props.clicked}
        >
            {props.toolbarOpen  
                ? 'Exhibitions'
                : 
                <Tooltip title="Exhibitions">
                    <ArtTrackRoundedIcon/>
                </Tooltip>
            }
        </NavigationItem>
        {props.userRole === "admin" &&
            <NavigationItem
                links={[
                    { name: 'Transactions', link: "/sales/transactions" }
                ]}
                isOpen={props.toolbarOpen}
                clicked={props.clicked}
            >
                {props.toolbarOpen
                    ? 'Sales'
                    :
                    <Tooltip title="Sales">
                        <MonetizationOnRoundedIcon />
                    </Tooltip>
                }
            </NavigationItem>
        }
        {props.userRole === "admin" && 
            <NavigationItem 
            links={[{ name: 'Murano', link:`/tagliapietra-branch`}]} 
                isOpen={props.toolbarOpen}
                clicked={props.clicked}
            > 
                {props.toolbarOpen
                ? 'Murano'
                :
                <Tooltip title="Murano">
                    <span>M</span>
                </Tooltip>
                } 
            </NavigationItem>
        }   
        {props.userRole === "murano" &&
            <NavigationItem
            links={[{ name: 'Seattle', link: `/tagliapietra-branch` }]}
                isOpen={props.toolbarOpen}
                clicked={props.clicked}
            >
                {props.toolbarOpen
                    ? 'Seattle'
                    :
                    <Tooltip title="Seattle">
                        <span>S</span>
                    </Tooltip>
                }
            </NavigationItem>
        }   
    </NavigationItemsSt>
);

export default navigationItems;