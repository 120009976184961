import React,{useState} from 'react';
import {NavLink} from 'react-router-dom';
import {colours} from '../../../../global-styles';
import styled from 'styled-components';

const NavigationItemSt = styled.li`
    margin: 5px;
    margin-left:${props=>props.isOpen? '20px' : 'unset'};
    padding: 10px;
    text-align: ${props=>props.isOpen? 'left': 'center'};
`;

const LinkSt = styled(NavLink)`
    color: ${colours.white01};
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 4px;

    &:hover{
        color: ${colours.grey03};
    }
    &.active{
        color: ${colours.grey05};
    }
    @media(max-width: 800px){
        color: ${colours.grey06};
    };
`;

const ItemSt = styled.button`
    color: ${colours.white01};
    font-size: 16px;
    padding: 0px;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 4px;

    &:hover{
        color: ${colours.grey03};
    }
    &.active{
        color: ${colours.grey05};
    }
    @media(max-width: 800px){
        color: ${colours.grey06};
    };
`;

const ExpandedLinks = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-left: 20px;
    &.active{
        color: ${colours.grey05};
    }
`;

const NavigationItem = ( props ) => {
    const [expand, setExpand] = useState(false);
    const link = ((props.links && props.links.length === 1) || !props.isOpen) ? props.links[0].link : null;
    return (
        <NavigationItemSt  data-id={props.children} isOpen={props.isOpen} >
            {(props.links.length < 2 || !props.isOpen) ?
                <LinkSt 
                    onClick={props.clicked}
                    to={link}
                    exact={props.exact}
                >
                    {props.children}
                </LinkSt>
                :
                <div>
                    <ItemSt
                        onClick={()=>setExpand(expand => !expand)}
                    >
                       {props.children} 
                    </ItemSt>
                    {(expand && props.isOpen) && 
                        <ExpandedLinks>
                            {props.links.map((x, index)=>{
                                return (
                                    <LinkSt
                                        key={index}
                                        to={x.link}
                                        exact={props.exact}
                                        onClick={props.clicked}
                                    >
                                        {x.name}
                                    </LinkSt>
                                )
                            })}
                        </ExpandedLinks>

                    }
                </div>
                
            }
            
        </NavigationItemSt>
    );
}
export default NavigationItem;