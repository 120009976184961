import axios from 'axios';

const instance = axios.create({

  // baseURL: 'http://qrator-staging-api-alb-1783685187.eu-west-2.elb.amazonaws.com/api/v1',
  baseURL: 'https://api.q-rator.com/api/v1',
	// baseURL: 'http://localhost:3002/api/v1',
});

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });

export default instance;