import styled from 'styled-components';

export const Content = styled.div`
	margin: 0px;
	background-color: transparent;
	height: ${props=> (props.route==="/login")? '100%': props.width<800 ? 'calc(100% - 60px)': '100%'};
	position: fixed;
	bottom: 0;
	z-index: 0;
	left: ${props=> (props.route==="/home" || props.route==="/login")? '0': props.width < 800 ? '0' : '60px'};
	width: ${props=> (props.route==="/home" || props.route==="/login")? '100%' : props.width < 800 ? '100%':'calc(100% - 60px)'};
	overflow-y: hidden;
	justify-content: center;
`;

export const App = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
`;