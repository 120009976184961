import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import * as S from './style.js';
import Aux from './../Aux/Aux';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import useWindowSize from '../../utils/hooks/windowSizeHook';
import {withRouter} from 'react-router-dom';
const Layout = props =>{
	const auth = props.isAuthenticated;
	const {width} = useWindowSize();
	return (
		<S.App>
			{auth &&
				<Aux>
					<Toolbar 
					logoutClicked={props.onLogout}
					isAuth={props.isAuthenticated} 
					userRole={props.userRole}
					drawerToggleClicked={props.onInitClick} />
					{width < 1000 &&
						<SideDrawer
							open={props.showSideBar}
							closed={props.onInitClick}
							isAuth={props.isAuthenticated}
							logoutClicked={props.onLogout}
							userRole={props.userRole}
						/>
					}
				</Aux>
			}
			<S.Content width={width} route={props.route}>
				{props.children}
			</S.Content>
		</S.App>			
	)	
}

const mapStateToProps = state => {
	return {
		showSideBar: state.UI.sideBarOpen,
		isAuthenticated: state.auth.token !== null,
		userRole: state.auth.userType
	};
}

const mapDispatchToProps = dispatch => {
	return {
		onInitClick: () => dispatch(actions.handleSideBar()),
		onLogout: () => dispatch(actions.logout())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));