import * as actionTypes from './actionTypes';

export const handleSideBar = () => {
	return {
		type: actionTypes.SIDEBAR_TOGGLE,
	};
};

export const handleModal = (isOpen, content) => {
	return {
		type: actionTypes.MODAL_TOGGLE,
		payload: {
			content: content,
			isOpen: isOpen
		}
	};
};

// export const toggleEdit = () => {
// 	return {
// 		type: actionTypes.TOGGLE_EDIT,
// 	};
// };