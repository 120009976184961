import * as actionTypes from './actionTypes';
import axios from "../../axios-instance";
import { showNotification } from './../../utils/notification';

export const fetchExhbitionsStart = () => {
	return {
		type: actionTypes.FETCH_EXHIBITIONS_START
	};
};

export const fetchExhbitionsSuccess = (primary, secondary) => {
	return {
		type: actionTypes.FETCH_EXHIBITIONS_SUCCESS,
		payload: {
			primary: primary,
			secondary: secondary
		}
	};
};

export const fetchExhibitionsFail = (error) => {
	return {
		type: actionTypes.FETCH_EXHIBITIONS_FAIL,
		payload: error
	};
};

export const initExhibitions = () => {
	return dispatch => {	
		dispatch(fetchExhbitionsStart());
		axios.get('/exhibitions')
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					const secondary = response.data.filter(item => {
						if (item.exhibition_events && item.exhibition_events.length < 1) {
							return false;
						} else if (item.exhibition_events && !!item.exhibition_events[0].content) {
							return (item.exhibition_events[0].content.role === "murano");
						} else {
							return false;
						}
					})
					const primary = response.data.filter(item => {
						if (item.exhibition_events && item.exhibition_events.length < 1) {
							return true;
						} else if (item.exhibition_events && !!item.exhibition_events[0].content) {
							return (item.exhibition_events[0].content.role !== "murano");
						} else {
							return true;
						}
					});
					dispatch( fetchExhbitionsSuccess(primary, secondary) );
				};
			} )
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch (fetchExhibitionsFail(error.response.data.errors) );
				}
			});
	};
};

export const deleteExhibitionStart = () => {
	return {
		type: actionTypes.DELETE_EXHIBITION_START
	};
};

export const deleteExhibitionSuccess = () => {
	return {
		type: actionTypes.DELETE_EXHIBITION_SUCCESS
	};
};

export const deleteExhibitionFail = () => {
	return {
		type: actionTypes.DELETE_EXHIBITION_FAIL
	};
};

export const deleteExhibition = (id) => {
	return dispatch => {
		dispatch( deleteExhibitionStart() );
		axios.delete(`/exhibitions/${id}`)
			.then( response => {
				if (response.status >= 200 && response.status <300 ){
					Promise.all([dispatch(deleteExhibitionSuccess()),dispatch(initExhibitions())]);
				};
			})
			.catch(error=>{
				if(error.response.status > 300 ){
					dispatch(deleteExhibitionFail(error.response.data.errors));
				}
			});
	};
};

export const selectedExhibition = () => {
	return {
		type: actionTypes.FETCH_SELECTED_EXHIBITION,
	};
};


export const focusedExhibitionSuccess = (data, id) => {
	return {
		type: actionTypes.SELECTED_EXHIBITION_DATA,
		payload: data,
		id: id
	};
};

export const addPiecesToExhibitionStart = () => {
	return {
		type: actionTypes.ADD_PIECE_TO_EXHIBITION_START
	};
};

export const addPiecesToExhibitionSuccess = () => {
	return {
		type: actionTypes.ADD_PIECE_TO_EXHIBITION_SUCCESS
	};
};

export const addPiecesToExhibitionFail = (error) => {
	return {
		type: actionTypes.ADD_PIECE_TO_EXHIBITION_FAIL,
		payload: error
	};
};

export const addPiecesToExhibition = (data, position, total, exhibition_id) => {
	return dispatch =>{
		dispatch(addPiecesToExhibitionStart());
		axios.post(`/layoutexhibitions`, data)
		.then( response => {
			if (response.status >= 200 && response.status < 300){
				if (position === total){
					Promise.all([dispatch(addPiecesToExhibitionSuccess()), dispatch(initExhibitions())])
					showNotification(` ${total+1} Artworks successfully added to the Exhibition.`)
					if (exhibition_id){
						dispatch(fetchPiecesInExhibition(exhibition_id))
					}
				} else {
					dispatch(addPiecesToExhibitionSuccess())
				};
			};
		})
		.catch(error=>{
			if (error.response.status > 300){
				dispatch(addPiecesToExhibitionFail(error.response.data.errors))
				showNotification(`Oops, something went wrong. Error: ${error.response.data.errors[0]}`)
			};
		});
	};
};

export const editLayoutPieceStart = ()=>{
	return  {
		type: actionTypes.EDIT_PIECE_LAYOUT_EXHIBITION_START
	};
};

export const editLayoutPieceSuccess = ()=>{
	return  {
		type: actionTypes.EDIT_PIECE_LAYOUT_EXHIBITION_SUCCESS
	};
};

export const editLayoutPieceFail = (error)=>{
	return  {
		type: actionTypes.EDIT_PIECE_LAYOUT_EXHIBITION_FAIL,
		payload: error
	};
};

export const editLayoutPiece = (id, id_exhibition, data) => {
	return dispatch => {
		dispatch(editLayoutPieceStart());
		axios.patch(`/layoutexhibitions/${id}`, data)
		.then(response=> {
			if( response.status >= 200 && response.status < 300){
				Promise.all([dispatch(editLayoutPieceSuccess()), dispatch(initExhibitions()), dispatch(fetchPiecesInExhibition(id_exhibition))])
				showNotification('Artwork Location Updated.')
			}
		})
		.catch(error => {
			if (error.response.status > 300){
				dispatch(editLayoutPieceFail(error.response.data.errors))
				showNotification(`Oops, something went wrong. Error: ${error.response.data.errors[0]}`, 'error')
			}
		});
	};
};

export const removeArtworkExhibitStart = () => {
	return {
		type: actionTypes.REMOVE_ARTWORK_EXHIBIT_START
	}
}

export const removeArtworkExhibitSuccess = () => {
	return {
		type: actionTypes.REMOVE_ARTWORK_EXHIBIT_SUCCESS
	}
}

export const removeArtworkExhibitFail = (error) => {
	return {
		type: actionTypes.REMOVE_ARTWORK_EXHIBIT_FAIL,
		payload: error
	}
}

export const removeArtworkExhibit = (id, exhibition_id) => {
	return dispatch =>{
		dispatch(removeArtworkExhibitStart());
		axios.delete(`/layoutexhibitions/${id}`)
		.then(response=>{
			if(response.status >=200 && response.status < 300){
				Promise.all([dispatch(removeArtworkExhibitSuccess()), dispatch(fetchPiecesInExhibition(exhibition_id))])
				showNotification('Artwork removed from exhibition')
			}
		})
		.catch(error=>{
			if(error.response.status > 300){
				dispatch(removeArtworkExhibitFail(error.response.data.errors))
				showNotification(`Oops! Something went wrong. Error: ${error.response.message}`, 'error')
			}
		});
	};
};



export const removeArtworkExhibitionStart = () => {
	return {
		type:actionTypes.REMOVE_ARTWORK_EXHIBITION_START
	};
};
export const removeArtworkExhibitionSuccess = () => {
	return {
		type: actionTypes.REMOVE_ARTWORK_EXHIBITION_START
	};
};
export const removeArtworkExhibitionFail = (error) => {
	return {
		type:actionTypes.REMOVE_ARTWORK_EXHIBITION_START,
		payload: error
	};
};

export const removeArtworkExhibition = (id, exhibition_id)=>{
	return dispatch =>{
		dispatch(removeArtworkExhibitionStart());
		axios.delete(`/layoutexhibitions/${id}`)
		.then( response => {
			if(response.status>=200 && response.status<300){
				Promise.all([dispatch(removeArtworkExhibitionSuccess()),dispatch(fetchPiecesInExhibition(exhibition_id))]);
			};
		})
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(removeArtworkExhibitionFail(error.response.data.errors));
			}
		});
	};
};

export const newExhibitionStart = () => {
	return {
		type: actionTypes.NEW_EXHIBITION_START
	};
};

export const newExhibitionSuccess = () => {
	return {
		type: actionTypes.NEW_EXHIBITION_SUCCESS
	};
};

export const newExhibitionFail = (error) => {
	return {
		type: actionTypes.NEW_EXHIBITION_FAIL,
		payload: error
	};
};

export const newExhibition = (formData) => {
	return dispatch => {
		dispatch(newExhibitionStart());
		axios.post('/exhibitions', formData)
			.then( response => {
				if (response.status>=200 && response.status<300){
					Promise.all([dispatch(newExhibitionSuccess()),dispatch(initExhibitions())])
					.then(showNotification(`Exhibition ${formData["exhibition_name"]} Initialised`));
				};
			})
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch(newExhibitionFail(error.response.data.errors));
				}
			});
	};
};

export const fetchPiecesInExhibition = (id) => {
	return dispatch => {
		dispatch(selectedExhibition());
		axios.get(`/exhibitions/${id}`)
			.then( response => {
				if (response.status >=200 && response.status<300) {
					dispatch(focusedExhibitionSuccess(response.data, id));
				};
			} )
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch(fetchExhibitionsFail(error.response.data.errors));
				}
			} );
	};
};