import styled from 'styled-components';
import {colours} from './../../../global-styles';


export const Toolbar = styled.div`
    height: calc(100% - 60px);
    width: ${props=> props.open? '250px' : '60px'};
    background-color: ${colours.green01};
    color: ${colours.white01};
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: space-between;
    z-index: 1;
    transition: width 0.1s ease-out;
`;

export const navigationItems = styled.div`

`;

export const controlItems = styled.div`
    display: flex;
    flex-direction: ${props=>props.open? 'row' : 'column'};
`;

export const Button = styled.button`
    font-size: 14px;
    width: 70px;
    margin: ${props=> props.open? '2px auto': 'unset'};
    color: ${props=> props.color || 'black'};
`;

export const ButtonLogout = styled.button`
    outline: none;
    border: none;
    background-color: inherit;
    cursor: pointer;
    font: inherit;
    color: #ffff;
    z-index: 3;
    font-size: 18px;
    padding: 5px;
    margin: auto;
    font-weight: 400;
    position: fixed;
    right: 10px;
    top: 2px;
    &:hover{
        color: red;
    }
`;
