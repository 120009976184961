import React, { Component } from 'react';
import Aux from './../../../hoc/Aux/Aux';
import Backdrop from './../Backdrop/Backdrop';
import styled from 'styled-components';

const ModalSt = styled.div`
	position: fixed;
	z-index: 600;
	background-color: white;
	width: max-content;
	width: 60%;
	border: 2px solid white;
	left: 20%;
	top: 20%;
	box-sizing: border-box;
	transition: all 0.3s ease-out;
	border-radius: 4px;
	overflow-y:auto;
	@media(max-width: 800px){
		width:80%;
		left:10%;
	}
`;
class Modal extends Component {

    shouldComponentUpdate ( nextProps, nextState ) {
        return nextProps!== this.props;
    }

    componentDidUpdate () {

    }

    render () {	
        return (
			<Aux>
				<Backdrop show={this.props.show} clicked={this.props.modalClosed} />
				<ModalSt 
				style={{
					transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
					opacity: this.props.show ? '1' : '0'
				}}>
					{this.props.children}
				</ModalSt>
			</Aux>
		)
	}
}

export default Modal;