import { createGlobalStyle, css } from 'styled-components';

const colours = {
  white01: '#FFFFFF',
  white02: '#eae1bb',
  grey01: '#EAEAEC',
  grey02: '#D5D6DA',
  grey03: '#92949E',
  grey04: '#565968',
  grey05: '#2F3245',
  grey06: 'black',
  slateGrey: '#2f4f4f',
  blue01: '#0A01AC',
  blue02: '#4D60C4',
  blue03: '#37458B',
  blue04: '#212F77',
  green01: '#28B57B',
  green02: '#067F64',
  red01: '#DF1243',
  red02: '#FF6E66',
  orange01: '#FF843A',
  yellow01: '#FFC001',
  gold01: '#bc9f65',
  gold02: '#a68e5e',

};

const sizes = {
  smallDesktop: 1400,
  desktop: 992,
  tablet: 768,
  phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

const reactDatepickerStyle = css`
  .react-datepicker {
    font-family: 'Saira', Avenir, 'Open Sans', 'Helvetica Neue', Helvetica,
      Arial, sans-serif;
    border: 0;
    border-radius: 0;
    background-color: ${colours.grey05};
    font-size: 12px;

    &__header {
      background-color: ${colours.grey05};
      border-bottom: 1px solid ${colours.grey04};
    }

    &__current-month {
      color: ${colours.white01};
      text-transform: uppercase;
      font-weight: bold;
    }

    &__day-name {
      color: ${colours.white01};
      width: 2rem;
      line-height: 2rem;
    }

    &__day {
      color: ${colours.white01};
      width: 2rem;
      line-height: 2rem;

      &--disabled {
        color: ${colours.grey04};
      }

      &--outside-month {
        color: ${colours.grey03};
      }

      &--keyboard-selected,
      &--selected,
      &:hover {
        background-color: ${colours.green01};
        color: ${colours.grey05};
        border-radius: 50%;
      }
    }
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle {
    border-bottom-color: ${colours.grey05};
    margin-top: -7px;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
    border-top-color: ${colours.grey05};
    margin-top: -7px;
  }
`;

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Saira:300,400,500,600,700');

  html,
  body {
    height: calc(100% - 16px);
    width: 100%;
    margin-top: inherit;
    margin:0px
  }

  body {
    font-family: 'proxima-nova', Avenir, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    letter-spacing: 1px;
    color: ${colours.grey05};
    background-color: ${colours.grey06};
    text-align: center
  }

  body.fontLoaded {
    font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    background-color: ${colours.grey06};
    min-width: 100%;

  }
  input, select {
      font-family: inherit;
      appearance: none;
      border: none;
      background-color: inherit !important;
      box-shadow: none;
  }


  button {
    font-family: inherit;
    letter-spacing: 1px;
    appearance: none;
    cursor: pointer;
    border: none;
    outline:none;
    background-color: inherit;

    &[disabled] {
      cursor: default;
    }
  }

  p,
  pre,
  label {
    font-family: 'proxima-nova', Avenir, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5em;
  }

  ${reactDatepickerStyle};
`;

export { colours, media, GlobalStyle };
