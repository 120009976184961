import * as actionTypes from './actionTypes';
import axios from "../../axios-instance";
import * as actions from './index';
import { showNotification } from './../../utils/notification';

export const setLists = ( primary, secondary ) => {
	return {
		type: actionTypes.FETCH_LISTS,
		payload: {
			primary: primary,
			secondary: secondary
		}
	};
};
export const initListsStart =()=> {
	return {
		type: actionTypes.FETCH_LISTS_START
	}
}
export const fetchListsFailed = (error) => {
	return {
		type: actionTypes.FETCH_LISTS_FAILED,
		payload: error
	};
};

export const removePieceFromListStart = () => {
	return {
		type: actionTypes.REMOVE_ITEM_LIST_START,
	}
}

export const removePieceFromListSuccess = (id) => {
	return {
		type: actionTypes.REMOVE_ITEM_LIST_SUCCESS,
		payload: id
	}
}

export const removePieceFromListFail = (error) => {
	return {
		type: actionTypes.REMOVE_ITEM_LIST_FAIL,
		payload: error
	}
}

export const removePieceFromList = (id) => {
	return dispatch => {
		dispatch(removePieceFromListStart() );
		axios.patch(`/pieces/${id}/piecesummaries/${id}`, { "list_id": null, "status": "Available" })
		.then(response => {
			if (response.status >= 200 && response.status < 300){
				Promise.all([dispatch( removePieceFromListSuccess(id)), dispatch(initLists()), dispatch(actions.initPieces())])
				
				showNotification('Artwork removed from list.');
			};
		})
		.catch( error => {
			if(error.response && error.response.status > 300 ){
				dispatch( removePieceFromListFail(error.response.data.errors) );
				showNotification(`Artwork not removed: ${error.response.data.errors[0]}.`, 'error');
			}
		});

	};	
};

export const selectedList = (id) => {
	return {
		type: actionTypes.LIST_MODAL_SELECTED,
		payload: id
	};
};


export const saveToListStart = () => {
	return {
		type: actionTypes.SAVE_TO_LIST_START
	};
};

export const saveToListSuccess = () => {
	return {
		type: actionTypes.SAVE_TO_LIST_SUCCESS
	};
};

export const saveToListFail = (error) => {
	return {
		type: actionTypes.SAVE_TO_LIST_FAIL,
		payload: error
	};
};

export const saveToList = (id, listedIds, galleryId) => {
	return dispatch => {
		dispatch(saveToListStart() );
		axios.patch(`/lists/${id}`, { "list":{ 'pieces_listed': listedIds, 'gallery_id': galleryId }} )
			.then(response => {
				if (response.status >=200 && response.status < 300 ) {
					Promise.all([dispatch( saveToListSuccess()), dispatch(actions.initPieces()), dispatch(initLists()), dispatch(initConsignments())])
					.then(showNotification('Artwork/s saved to existing list.'));
				};
			} )
			.catch(error => { 
				if(error.response.status > 300 ){
					dispatch(saveToListFail(error.response.data.errors));
					showNotification(`Drafted Artworks not saved: ${error.response.data.errors[0]}.`, 'error');
				}
			});
	}			
}

export const addPieceToList = (list_id, artwork_id) => {
	return dispatch => {
		dispatch(saveToListStart() );
		axios.patch(`/lists/${list_id}`, {"list": {"pieces_listed": artwork_id } } )
		.then(response => {
			if (response.status >= 200 && response.status < 300){
				Promise.all([dispatch( saveToListSuccess()), dispatch(actions.initPieces()),dispatch(initLists()), dispatch(initConsignments())])
				.then(showNotification('Artwork saved to existing list.'));
			};
		} )
		.catch(error => {
			if(error.response.status > 300 ){
				dispatch(saveToListFail(error.response.data.errors));
				showNotification(`Artwork listing error: ${error.response.data.errors[0]}`, 'error');
			}
		})
	};
};



export const pushToConsignStart = () => {
	return {
		type: actionTypes.PUSH_TO_CONSIGN_START
	};
};

export const pushToConsignSuccess = () => {
	return {
		type: actionTypes.PUSH_TO_CONSIGN_SUCCESS
	};
};

export const pushToConsignFail = (error) => {
	return {
		type: actionTypes.PUSH_TO_CONSIGN_FAIL,
		payload: error
	};
};

export const pushToConsign = (id, list_id) => {
	return dispatch => {
		dispatch(pushToConsignStart());
		axios.post(`/consignment/${id}`, {list_added_id: list_id})
		.then(response => {
			if (response.status >= 200 || response.status < 300){
				Promise.all([dispatch(pushToConsignSuccess()),dispatch(initLists()),dispatch(initConsignments()),dispatch(actions.initPieces())])
				.then(showNotification('Selected list added to consignment successfully.'));
			};
		})
		.catch(error => {
			if(error.response.status > 300 ){
				dispatch(pushToConsignFail(error.response.data.errors));
				showNotification(`List merge unsuccessfull: ${error.response.data.errors[0]}`, 'error');
			}
		});
	}
}

export const clearModalState = () => {
	return {
		type: actionTypes.CLEAR_MODAL_STATE
	}
}

export const listClicked = (data, id, location) => {
	return {
		type: actionTypes.FOCUSED_LIST,
		payload: {
			pieces: data,
			id: id,
			location: location
		}
	};
};

export const createListStart = () => {
	return{
		type: actionTypes.CREATE_LIST_START
	};
};

export const createListSuccess = () => {
	return{
		type: actionTypes.CREATE_LIST_SUCCESS
	};
};

export const createListFail = (error) => {
	return{
		type: actionTypes.CREATE_LIST_FAIL,
		payload: error
	};
};

export const deleteListStart = () => {
	return{
		type: actionTypes.DELETE_LIST_START
	};
};

export const deleteListSuccess = () => {
	return{
		type: actionTypes.DELETE_LIST_SUCCESS
	};
};

export const deleteListFail = (error) => {
	return{
		type: actionTypes.DELETE_LIST_FAIL,
		payload: error
	};
};

export const saveAsListStart = () => {
	return {
		type: actionTypes.SAVE_AS_LIST_START
	};
};

export const saveAsListSuccess = () => {
	return{
		type: actionTypes.SAVE_AS_LIST_SUCCESS
	};
};

export const saveAsListFail = (error) => {
	return{
		type: actionTypes.SAVE_AS_LIST_FAIL,
		payload: error
	};
};

export const saveAsList = (formData) => {
	return dispatch => {
		dispatch(saveAsListStart() );
		axios.post(`/lists`, {"list": formData } )
			.then(response => {
				if (response.status >= 200 && response.status < 300){
					Promise.all([dispatch(saveAsListSuccess() ), dispatch( actions.initPieces() ), dispatch(initLists())])
					.then(showNotification(`${formData["list_name"]} successfully saved.`));
				};
			})
			.catch(error => {
				if(error.response.status > 300 ){
					dispatch( saveAsListFail(error.response.data.errors));
					showNotification(`List ${formData["list_name"]} saving error: ${error.response.data.errors[0]}.`,'error');
				}
			});
	}
}

export const deleteList = (id, name) => {
	return dispatch => {
		dispatch(deleteListStart() );
			axios.delete(`/lists/${id}`)
			.then(response => {
				if (response.status >= 200 && response.status < 300){
					Promise.all([dispatch( deleteListSuccess() ),dispatch( initLists())])
					.then(showNotification(`List ${name} deleted.`));
				};
			} )
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch( deleteListFail(error.response.data.errors) );
					showNotification(`List could not be deleted: ${error.response.data.errors}.`, 'error');
				}
		});	
	};
};

export const createList = (formData) => {
	return dispatch => {
		dispatch(createListStart() );
		axios.post('/lists', formData )
			.then( response => {
				if (response.status >= 200 && response.status < 300 ){
					Promise.all([dispatch( createListSuccess()),dispatch( initLists() )])
					.then(showNotification(`List ${formData["list_name"]} successfully saved.`))
				}
			} )
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch( createListFail(error.response.data.errors) )
					showNotification(`Failed list ${formData["list_name"]} creation: ${error.response.data.errors[0]}.`, 'error');
				}
			});
	};
};

export const fetchPiecesInList = (id, location) => {
	return dispatch => {
		axios.get(`/lists/${id}`)
			.then( response => {
				dispatch(listClicked(response.data, id, location))
			} )
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch(fetchListsFailed(error.response.data.errors));
					showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');	
				}
			} );
	};
};

export const clearSelectedList = () => {
	return {
		type: actionTypes.CLEAR_SELECTED_LIST
	}
}

export const initLists= () => {
	return dispatch => {
		dispatch(initListsStart());
		axios.get('/lists')
			.then(response => {
				const secondary = response.data.filter(item => {
						if (item.list_events && item.list_events.length < 1) {
							return false;
						} else if (item.list_events && !!item.list_events[0].content) {
							return (item.list_events[0].content.role === "murano");
						} else {
							return false;
						}
					})
					const primary = response.data.filter(item => {
						if (item.list_events && item.list_events.length < 1) {
							return true;
						} else if (item.list_events && !!item.list_events[0].content) {
							return (item.list_events[0].content.role !== "murano");
						} else {
							return true;
						}
					});
				dispatch(setLists(primary, secondary));
			} )
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch(fetchListsFailed(error.response.data.errors));
					showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
				}
			} );
	};
};

export const consignmentClicked = (id, location) => {
	return {
		type: actionTypes.CONSIGNMENT_CLICKED,
		payload: {
			id: id,
			location: location
		}
	};
};


export const setConsignments = ( primary, secondary ) => {
	return {
		type: actionTypes.FETCH_CONSIGNMENTS,
		payload: {
			primary: primary,
			secondary: secondary
		}
	};
};
export const fetchConsignmentsStart = () => {
	return {
		type: actionTypes.FETCH_CONSIGNMENTS_START
	}
}
export const fetchConsignmentsFailed = (error) => {
	return {
		type: actionTypes.FETCH_CONSIGNMENTS_FAILED,
		payload: error
	};
};

export const initConsignments= () => {
	return dispatch => {
		dispatch(fetchConsignmentsStart());
		axios.get('/consignments')
		.then(response => {
			if (response.status >= 200 && response.status < 300) {
				const secondary = response.data.filter(item => {
						if (item.consignment_events && item.consignment_events.length < 1) {
							return false;
						} else if (item.consignment_events && !!item.consignment_events[0].content) {
							return (item.consignment_events[0].content.role === "murano" );
						} else {
							return false;
						}
					})
					const primary = response.data.filter(item => {
						if (item.consignment_events && item.consignment_events.length < 1) {
							return true;
						} else if (item.consignment_events && !!item.consignment_events[0].content) {
							return (item.consignment_events[0].content.role !== "murano");
						} else {
							return true;
						}
					});
				dispatch(setConsignments(primary, secondary));
			};				
		})
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(fetchConsignmentsFailed(error.response.data.errors));
				showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
			}
		} );
	};
};


export const consignmentFocused = (consigned, id, location) => {
	return {
		type: actionTypes.FOCUSED_CONSIGNMENT,
		payload: {
			pieces: consigned,
			id: id,
			location: location
		}
	}
}

export const fetchPiecesInConsign = (id, location) => {
	return dispatch => {
		axios.get(`/consignments/${id}`)
			.then( response => {
				if (response.status >= 200 && response.status  < 300 ) {
					dispatch(consignmentFocused(response.data, id, location))
				}
			} )
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch(fetchConsignmentsFailed(error.response.data.errors));
					showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
				}
			} );
	};
};

export const registerConsignmentSuccess = () => {
	return {
		type: actionTypes.REGISTER_CONSIGNMENT_SUCCESS,
	}
}

export const registerConsignmentFail = (error) => {
	return {
		type: actionTypes.REGISTER_CONSIGNMENT_FAIL,
		payload: error
	}
}

export const removePieceFromConsignmentStart = () => {
	return {
		type: actionTypes.REMOVE_ITEM_CONSIGN_START
	};
};

export const removePieceFromConsignmentSuccess = (id) => {
	return {
		type: actionTypes.REMOVE_ITEM_CONSIGN_SUCCESS,
		payload: id
	};
};

export const removePieceFromConsignmentFail = (error) => {
	return {
		type: actionTypes.REMOVE_ITEM_CONSIGN_FAIL,
		payload: error
	};
};

export const deletePieceCons = (id) => {
	return dispatch => {
		dispatch( removePieceFromConsignmentStart() );
		axios.patch(`/pieces/${id}/piecesummaries/${id}`, {"list_id":null, "status": "Available" } )
			.then( response => {
				if (response.status >= 200 && response.status  < 300 ) {
					Promise.all([dispatch( removePieceFromConsignmentSuccess(id) ),dispatch( initConsignments() ),dispatch(actions.initPieces()), dispatch(actions.initGalleries())])
					.then(showNotification('Artwork removed from consignment'));
				};
			} )
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch( removePieceFromConsignmentFail(error.response.data.errors) );
					showNotification(`Artwork not removed from consignment: ${error.response.data.errors[0]}.`, 'error');
				}
			});
	}

}

export const registerConsignmentStart = () => {
	return {
		type: actionTypes.REGISTER_CONSIGNMENT_START
	};
}

export const registerConsignment = (formData) => {
	return dispatch => {
		dispatch(registerConsignmentStart());
		axios.post('/consignments', formData)
		.then(response => {
			if (response.status >= 200 && response.status < 300 ){
				Promise.all([dispatch(registerConsignmentSuccess()), dispatch(actions.initPieces()),dispatch(initConsignments()), dispatch(initLists())])
				.then(showNotification(`New consignement ${formData["code"]} successfully registered.`));
			};
		})
		 .catch(error => {
		 	if(error.response.status > 300 ){
				dispatch(registerConsignmentFail(error.response.data.errors));
				showNotification(`Consignment ${formData["code"]} - ${formData["name"]} registration failed: ${error.response.data.errors[0]}.`, 'error');
			}
		})
	};
};

export const saveAsConsignStart = () => {
	return{
		type: actionTypes.SAVE_AS_CONSIGN_START
	};
};

export const saveAsConsignSuccess = () => {
	return{
		type: actionTypes.SAVE_AS_CONSIGN_SUCCESS
	};
};

export const saveAsConsignFail = (error) => {
	return{
		type: actionTypes.SAVE_AS_CONSIGN_FAIL,
		payload: error
	};
};
export const saveAsConsign = (formData) => {
	return dispatch => {
		dispatch(saveAsConsignStart() );
		
		axios.post('/consignments', formData )
		.then( response => {			
			if (response.status >= 200 && response.status < 300){ 
				Promise.all([dispatch(saveAsConsignSuccess()), dispatch(actions.initPieces()), dispatch(initConsignments())])
				.then(showNotification(`Artworks saved in new consignement ${formData.consignment["code"]}.`));
			}	
		})
		.catch(error => {
			if(error.response.status > 300 ){
				dispatch(saveAsConsignFail(error.response.data.errors));
				showNotification(`Error during consignment registration: ${error.response.data.errors[0]}.`,'error');
			}
		});
	};
};

export const deleteConsignStart = () => {
	return {
		type: actionTypes.DELETE_CONSIGN_START
	};
};
export const deleteConsignSuccess = () => {
	return {
		type: actionTypes.DELETE_CONSIGN_SUCCESS
	};
};
export const deleteConsignFail = (error) => {
	return {
		type: actionTypes.DELETE_CONSIGN_FAIL,
		payload: error
	};
};

export const deleteConsign = (id, code) => {
	return dispatch => {
		dispatch( deleteConsignStart());
		axios.delete(`/consignments/${id}`)
		.then(response => {
			if (response.status >= 200 && response.status  < 300 ) {
				Promise.all([dispatch(deleteConsignSuccess()), dispatch(initConsignments()), dispatch(initLists())] )
				.then(showNotification(`Consignment ${code} Deleted.`));
			};
		} )
		.catch(error => {
			if(error.response.status > 300 ){
				dispatch( deleteListFail(error.response.data.errors));
				showNotification(`Error during consignment deletion: ${error.response.data.errors[0]}.`, 'error');
			}
		});
	};
};

export const mergeToConsignStart = ()=> {
	return {
		type: actionTypes.MERGE_TO_CONSIGN_START
	};
};
export const mergeToConsignSuccess = ()=> {
	return {
		type: actionTypes.MERGE_TO_CONSIGN_SUCCESS
	};
};
export const mergeToConsignFail = (error)=> {
	return {
		type: actionTypes.MERGE_TO_CONSIGN_FAIL,
		payload: error
	};
};
export const mergeToConsign = (id, list_id) => {
	return dispatch =>{
		dispatch(mergeToConsignStart());
		axios.patch(`/consignments/${id}`, {"list_added_id": list_id})
		.then( response => {
			if (response.status>= 200 && response.status< 300){
				Promise.all([dispatch(mergeToConsignSuccess()), dispatch(initLists()),dispatch(initConsignments()), dispatch(actions.initPieces())])
				.then(showNotification(`List added to existing consignment.`));
			};
		})
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(mergeToConsignFail(error.response.data.errors));
				showNotification(`Merge List to consignment failed. Error: ${error.response.data.errors[0]}`);
			}
		});
	};
};

export const editConsignStart = () =>{
	return {
		type: actionTypes.EDIT_CONSIGN_START
	};
};


export const editConsignSuccess= () =>{
	return {
		type: actionTypes.EDIT_CONSIGN_SUCCESS
	};
};

export const editConsignFail = (error) =>{
	return {
		type: actionTypes.EDIT_CONSIGN_FAIL,
		payload: error
	};
};

export const editConsign = (id, data) => {
	return dispatch => {
		dispatch(editConsignSuccess());
		axios.patch(`/consignments/${id}`, data)
		.then(response=>{
			if (response.status >= 200 && response.status < 300){
				Promise.resolve([dispatch(editConsignSuccess()), dispatch(initConsignments())])
				showNotification(`Consignment successfully updated.`)
			}
		})
		.catch(error=>{
			if (error.response.status > 300){
				dispatch(editConsignFail())
				showNotification(`Consignment update failed.`, 'error')
			}
		})
	}
}





