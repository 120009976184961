import React from 'react';
import styled, { css } from 'styled-components';
import { colours } from './../../../global-styles';


const errorStyles = css`
  background-color: ${colours.red01};
  color: ${colours.grey06};
  padding: 15px 10px;
`;

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 300px;
  color: ${colours.white01};
  padding: 15px 10px;
  background-color: ${colours.green01};
  border-radius: 5px;
  ${({ error }) => error && errorStyles};
`;

const Notification = ({ message, error }) => (
  <NotificationContainer error={error} >
    <div>{message}</div>
  </NotificationContainer>
);

export default Notification;
