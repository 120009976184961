import * as actionTypes from './actionTypes';
import axios from "../../axios-instance.js";
import { showNotification } from './../../utils/notification';
import * as actions from './index';

export const fetchClientsStart =() => {
	return {
		type: actionTypes.FETCH_CLIENTS_START
	};
};

export const fetchClientsSuccess =(primary, secondary) => {
	return {
		type: actionTypes.FETCH_CLIENTS_SUCCESS,
		payload: {
			primary: primary,
			secondary: secondary
		}
	};
};

export const fetchClientsFail =(error) => {
	return {
		type: actionTypes.FETCH_CLIENTS_FAIL,
		payload: error
	};
};

export const initClients = () => {
	return dispatch => {
		dispatch(fetchClientsStart());
		axios.get('/clients')
		.then(response=>{
			if (response.status >= 200 && response.status < 300) {
				const secondary = response.data.filter(item => {
						if (item.client_events && item.client_events.length < 1) {
							return false;
						} else if (item.client_events && !!item.client_events[0].content) {
							return (item.client_events[0].content.role === "murano");
						} else {
							return false;
						}
					})
					const primary = response.data.filter(item => {
						if (item.client_events && item.client_events.length < 1) {
							return true;
						} else if (item.client_events && !!item.client_events[0].content) {
							return (item.client_events[0].content.role !== "murano");
						} else {
							return true;
						}
					});
				dispatch(fetchClientsSuccess(primary, secondary));
			};
		})
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(fetchClientsFail(error.response.data.errors));
				showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
			}
		});
 	};
};

export const registerClientStart= () => {
	return {
		type:actionTypes.REGISTER_CLIENT_START
	};
};

export const registerClientSuccess = (data) => {
	return {
		type: actionTypes.REGISTER_CLIENT_SUCCESS,
		payload: data
	};
};

export const registerClientFail = (error) => {
	return {
		type: actionTypes.REGISTER_CLIENT_FAIL,
		payload: error
	};
};

export const registerClient = (data)=> {
	return dispatch => {
		dispatch(registerClientStart());
		axios.post('/clients', data)
		.then( response => {
			if (response.status >= 200 && response.status <300) {
				Promise.all([dispatch(registerClientSuccess(response.data)), dispatch(initClients())])
				showNotification(`${data.client.last_name} ${data.client.first_name} successfully registered.`)
			};
		})
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(registerClientFail(error.response.data.errors));
				showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
			}
		});
	}
}

export const editClientStart = () =>{
	return {
		type: actionTypes.EDIT_CLIENT_START
	};
};

export const editClientSuccess = () =>{
	return {
		type: actionTypes.EDIT_CLIENT_SUCCESS
	};
};

export const editClientFail = (error) =>{
	return {
		type: actionTypes.EDIT_CLIENT_FAIL,
		payload: error
	};
};

export const editClientDetails = (id, data) => {
	return dispatch => {
		dispatch(editClientStart());
		axios.patch(`/clients/${id}`, data.client)
		.then( response => {
			if (response.status >= 200 && response.status <300) {
				Promise.all([dispatch(editClientSuccess()), dispatch(initClients())])
				showNotification(`${data.client.last_name} ${data.client.first_name} details successfully updated.`)
			};
		})
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(editClientFail(error.response.data.errors));
				showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
			}
		});
	};
};

export const editClientContacts = (id, data) => {
	return dispatch => {
		dispatch(editClientStart());
		axios.post(`/clients/${id}/contacts`, data.contact)
		.then( response => {
			if (response.status >= 200 && response.status <300) {
				Promise.all([dispatch(editClientSuccess()), dispatch(initClients())])
				showNotification(`${data.client.last_name} ${data.client.first_name} contact details successfully updated.`)
			};
		})
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(editClientFail(error.response.data.errors));
				showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
			}
		});
	};
};

export const editClientAddress = (id, data) => {
	return dispatch => {
		dispatch(editClientStart());
		axios.post(`/clients/${id}/addresses`, data.address)
		.then( response => {
			if (response.status >= 200 && response.status <300) {
				Promise.all([dispatch(editClientSuccess()), dispatch(initClients())])
				showNotification(`${data.client.last_name} ${data.client.first_name} address successfully updated.`)
			};
		})
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(editClientFail(error.response.data.errors));
				showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
			}
		});
	};
};

export const deleteClientStart = () => {
	return {
		type: actionTypes.DELETE_CLIENT_START
	};
};

export const deleteClientSuccess = () => {
	return {
		type: actionTypes.DELETE_CLIENT_SUCCESS
	};
};

export const deleteClientFail = (error) => {
	return {
		type: actionTypes.DELETE_CLIENT_FAIL,
		payload: error
	};
};

export const deleteClient = (id) => {
	return dispatch => {
		dispatch(deleteClientStart());
		axios.delete(`/clients/${id}`)
		.then( response => {
			if (response.status >= 200 && response.status <300) {
				Promise.all([dispatch(deleteClientSuccess()), dispatch(initClients())])
				showNotification(`Client successfully deleted.`)
			};
		})
		.catch( error => {
			if(error && error.response.status > 300 ){
				dispatch(deleteClientFail(error.response.data.errors));
				showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
			}
		});
	};
};

export const getClientsPiecesStart = () => {
	return {
		type:  actionTypes.FETCH_CLIENTS_PIECES_START
	};
};
export const getClientsPiecesSuccess = (data) => {
	return {
		type: actionTypes.FETCH_CLIENTS_PIECES_SUCCESS,
		payload: data
	};
};
export const getClientsPiecesFail = (error) => {
	return {
		type: actionTypes.FETCH_CLIENTS_PIECES_FAIL
	};
};
export const fetchPiecesInClient = (id) => {
	return dispatch => {
		dispatch(getClientsPiecesStart());
		axios.get(`/clients/${id}`)
		.then(response=>{
			if (response.status >= 200 && response.status <300) {
				dispatch(getClientsPiecesSuccess(response.data));
			};
		})
		.catch( error => {
				if(error && error.response.status > 300 ){
					dispatch(getClientsPiecesFail(error.response.data.errors));
					showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
				}

		});
 	};
};


// transaction logics

export const initTransactionsStart =() => {
	return {
		type: actionTypes.FETCH_TRANSACTIONS_START
	};
};

export const initTransactionsSuccess =(primary, secondary) => {
	return {
		type: actionTypes.FETCH_TRANSACTIONS_SUCCESS,
		payload: {
			primary: primary,
			secondary: secondary
		}
	};
};

export const initTransactionsFail =(error) => {
	return {
		type: actionTypes.FETCH_TRANSACTIONS_FAIL,
		payload: error
	};
};

export const initTransactions = () => {
	return dispatch => {
		dispatch(initTransactionsStart());
		axios.get('/trnsctns')
		.then(response => {
			if (response.status >= 200 && response.status < 300) {
				const primary = response.data;
				const secondary = [];
				dispatch(initTransactionsSuccess(primary, secondary))
			}
		})
		.catch(error=>{
			if(error.response.status > 300){
				dispatch(initTransactionsFail(error.response.data.errors))
			}
		})
	}
}

export const createTransactionStart =  () => {
	return {
		type:actionTypes.CREATE_TRANSACTION_START
	};
};

export const createTransactionSuccess =  (response) => {
	return {
		type:actionTypes.CREATE_TRANSACTION_SUCCESS,
		payload: response
	};
};

export const createTransactionFail =  (error) => {
	return {
		type:actionTypes.CREATE_TRANSACTION_FAIL,
		payload: error
	};
};

export const createTransaction =  (data) => {
	return dispatch => {
		dispatch(createTransactionStart());
		axios.post('/trnsctns', {transaction:data})
		.then(response=> {
			if (response.status >=200 && response.status < 300){
				Promise.all([dispatch(actions.clearDraftList()), dispatch(createTransactionSuccess(response.data)), dispatch(initTransactions()), dispatch(initClients()), dispatch(actions.initPieces())])
				showNotification('Transaction successfully created.')
			};
		})
		.catch( error => {
			if (error.response.status > 300 ){
				dispatch(createTransactionFail(error.response.data.errors));
				showNotification(`Oops, something went wrong! Error: ${error.response.data.errors[0]}`,'error');
			};
		});
	};
};


export const editTransactionStart = () => {
	return {
		type: actionTypes.EDIT_TRANSACTION_START
	}
};

export const editTransactionSuccess = (data) => {
	return {
		type: actionTypes.EDIT_TRANSACTION_SUCCESS,
		payload: data
	}
};

export const editTransactionFail = (error) => {
	return {
			type: actionTypes.EDIT_TRANSACTION_FAIL,
			paylod: error
		}
};

export const editTransaction = (id, data, code) => {
	return dispatch =>{
		dispatch(editTransactionStart());
		axios.patch(`/trnsctns/${id}`, data)
		.then(response => {
			if (response.status >= 200 && response.status <300){
				Promise.all([dispatch(editTransactionSuccess()), dispatch(initTransactions()), dispatch(initClients()), dispatch(actions.initPieces())]);
				showNotification(`Transaction ${code} updated.`)
			}
		})
		.catch(error => {
			if (error.response.status > 300 ){
				dispatch(editTransactionFail(error.response.data.errors));
				showNotification(`Oops, something went wrong! Error: ${error.response.data.errors[0]}`,'error');
			}
		});
	};
};

export const selectTransaction = (id) => {
	return {
		type: actionTypes.SELECT_TRANSACTION,
		payload: id
	};
};

export const removeTransaction = (id) => {
	return {
		type: actionTypes.REMOVE_TRANSACTION,
		payload: id
	};
};

export const clearSelectedTransactions = () => {
	return {
		type: actionTypes.CLEAR_SELECTED_TRANSACTIONS
	}
}

export const deleteTransactionStart = () => {
	return {
		type: actionTypes.DELETE_TRANSACTION_START  ,
	};
};

export const deleteTransactionSuccess = () => {
	return {
		type: actionTypes.DELETE_TRANSACTION_SUCCESS,
	};
};

export const deleteTransactionFail = (error) => {
	return {
		type: actionTypes.DELETE_TRANSACTION_FAIL,
		payload: error
	};
};

export const deleteTransaction = (id) => {
	return dispatch=>{
		dispatch(deleteTransactionStart());
		axios.delete(`/trnsctns/${id}`)
		.then(response=>{
			if (response.status >= 200 &&  response.status < 300){
				Promise.all([dispatch(deleteTransactionSuccess()), dispatch(initTransactions()), dispatch(actions.initPieces())])
				showNotification('Transaction deleted.')
			}
		})
		.catch(error=>{
			if(error.response.status > 300){
				dispatch(deleteTransactionFail())
				showNotification(`Oops, something went wrong. Error: ${error.response.data.errors[0]}`)
			}
		});
	};
};