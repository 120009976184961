import * as actionTypes from './actionTypes';
import { showNotification } from './../../utils/notification';
import * as actions from './index';
import axios from "../../axios-instance";

export const setGalleries = ( data ) => {
	return {
		type: actionTypes.FETCH_GALLERIES,
		payload: data
	};
};
export const initGalleriesStart = () => {
	return {
		type: actionTypes.FETCH_GALLERIES_START
	}
}
export const fetchGalleriesFailed = (error) => {
	return {
		type: actionTypes.FETCH_GALLERIES_FAILED,
		error: error
	};
};

export const deleteGallery = (id) => {
	return dispatch => {
		dispatch(deleteGalleryStart() );
		axios.delete(`/galleries/${id}`)
		.then( response => {
			if (response.status >= 200 && response.status< 300) {
				Promise.all([dispatch( deleteGallerySuccess() ), dispatch( initGalleries() )])
				.them(showNotification(`Gallery deletion successful.`));
			};
		} )
		.catch( error => {
			if(error.reponse && error.response.status > 300 ){
				dispatch(deleteGalleryFail(error.response.data.errors))
				showNotification(`Gallery deletion failed, error: ${error.response.data.errors[0]}.`, 'error');
			}
		});
	};
};

export const deleteGalleryStart = () => {
	return {
		type: actionTypes.GALLERY_DELETED_START
	};
};

export const deleteGallerySuccess = () => {
	return {
		type: actionTypes.GALLERY_DELETED_SUCCESS
	};
};

export const deleteGalleryFail = () => {
	return {
		type: actionTypes.GALLERY_DELETED_FAIL
	};
};

export const initGalleries= () => {
	return dispatch => {
		dispatch(initGalleriesStart());
		axios.get('/galleries')
			.then( response => {
				if (response.status >= 200 && response.status < 300) {
					dispatch(setGalleries(response.data))
				};
			} )
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch(fetchGalleriesFailed(error.response.data.errors));
					showNotification(`Oops, somwthing went wrong. ${error.response.data.errors[0]}`);
				}

			} );
	};
}; 

export const getGalleryArtworksStart = () => {
	return {
		type: actionTypes.FETCH_GALLERY_ARTWORKS_START
	}
}
export const getGalleryArtworksSuccess = (data) => {
	return {
		type: actionTypes.FETCH_GALLERY_ARTWORKS_SUCCESS,
		payload: data
	}
}
export const getGalleryArtworksFail = (error) => {
	return {
		type: actionTypes.FETCH_GALLERY_ARTWORKS_FAIL,
		payload: error
	}
}

export const getGalleryArtworks = (id) => {
	return dispatch => {
		dispatch(getGalleryArtworksStart());
		axios.get(`/galleries/${id}`)
		.then(response => {
			if (response.status >= 200 && response.status<300){
				dispatch(getGalleryArtworksSuccess(response.data))
			};
		})
		.catch(error=>{
			if(error.response.status > 300 ){
				dispatch(getGalleryArtworksFail(error.response.data.errors));
				showNotification(`Oops, something went wrong! error: ${error.response.data.errrors[0]}`,'error')
			}
		});
	};
};

export const registerGalleryStart = () => {
	return {
		type: actionTypes.REGISTER_GALLERY_START
	};
}

export const registerGallerySuccess = (id,formData) => {
	return {
		type: actionTypes.REGISTER_GALLERY_SUCCESS,
	}
}

export const registerGalleryFail = (error) => {
	return {
		type: actionTypes.REGISTER_GALLERY_FAIL,
		error: error
	}
}

export const registerGallery = (formData, clientData) => {
	return dispatch => {
		dispatch(registerGalleryStart());
		axios.post('/galleries',formData)
			.then( response => {
				if (response.status >= 200 && response.status< 300) {
					Promise.all([dispatch( registerGallerySuccess() ),dispatch( initGalleries() ), dispatch(actions.registerClient(clientData))])
					.then(showNotification('Gallery registration successfull.'));
				};
			} )
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch (registerGalleryFail (error.response.data.errors) );
					showNotification(`Gallery registration failed: ${error.response.data.errors[0]}.`, 'error');
				}
			}); 
	};
};

export const editGalleryStart = () => {
	return {
		type: actionTypes.EDIT_GALLERY_START
	};
};

export const editGallerySuccess = () => {
	return {
		type: actionTypes.EDIT_GALLERY_SUCCESS
	};
};

export const editGalleryFail = () => {
	return {
		type: actionTypes.EDIT_GALLERY_FAIL
	}
};

export const editGallery = (id, galleryData) => {
	return dispatch => {
		dispatch(editGalleryStart());
		axios.patch(`/galleries/${id}`, galleryData)
		.then( response => {
			if (response.status >= 200 && response.status < 300 ){
				Promise.all([dispatch(editGallerySuccess()), dispatch(initGalleries())])
				showNotification(`Gallery ${galleryData["name"]} successfully edited.`)
			}
		})
		.catch(error => {
			if(error.response.status > 300 ){
				dispatch(editGalleryFail(error.response.data.errors));
				showNotification(`Gallery edit error: ${error.response.data.errors[0]}.`, 'error');
			}
		})
	}
}



