import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState={
	sideBarOpen: false,
	modal: {
		modalOpen: false,
		modalContent: null
	}
}

const openSideBar = (state,action) =>{
	return updateObject( state, {sideBarOpen: !state.sideBarOpen} )
}

const modalToggle = (state, action) => {
	const updatedState = {
		modalOpen: action.payload.isOpen,
		modalContent: action.payload.content
	};
	return updateObject(state, {modal: updatedState})
}

const reducer = (state = initialState, action) => {
	switch(action.type){
		case actionTypes.SIDEBAR_TOGGLE:
			return openSideBar(state, action);
		case actionTypes.MODAL_TOGGLE:
			return modalToggle(state, action);
		default:
			return state;
	}
};

export default reducer;