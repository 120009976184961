import React from 'react';
import styled from 'styled-components';
import linoTagliapietra from '../../../assets/images/logo_gold.png';

const Logo = styled.div`
    background-color: inherit;
    margin: 12px auto;
    height:36px;

    img{
        width: 70%;
        height: 100%;
        object-fit: contain;
    }
    @media (max-width: 600px){
        img {
            width: 50%;
        }
    }
`;

const logo = (props) => (
    <Logo>
        <img src={linoTagliapietra} alt="Lino Tagliapietra" />
    </Logo>
);

export default logo;