import React from 'react';
import styled from 'styled-components';

const DrawerToggleSt = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin: auto;
    margin-left: 5px;
    box-sizing: border-box;
    cursor: pointer;
    position: fixed;
    top: 0px;
    left:10px;

    div{
        width: 90%;
        height: 3px;
        margin: 3px;
        border-radius: 1px;
        background-color: white;
    }
    @media (min-width: 1000px) {
        display: none;
    }   
`;

const drawerToggle = (props) => (
    <DrawerToggleSt onClick={props.clicked}>
        <div></div>
        <div></div>
        <div></div>
    </DrawerToggleSt>
);

export default drawerToggle;