import React, {useState} from 'react';
import * as S from './style';
import Logo from '../Logo/Logo';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import NavigationItems from '../NavigationItems/NavigationItems';
import useWindowSize from './../../../utils/hooks/windowSizeHook';
import CloseIcon from '@material-ui/icons/CloseRounded';
import OpenIcon from '@material-ui/icons/ArrowForwardIosRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink, withRouter } from 'react-router-dom';
import { colours } from '../../../global-styles';

const Toolbar = ( props ) => {
	const [toolbarOpen, setToolbarOpen] = useState(false);
	const {width} = useWindowSize();
	return (
		<React.Fragment>
		{props.location.pathname === "/home" &&
			<React.Fragment>
				<header style={{height: '60px', width: '100%' }}>
					<Logo />
				</header>
				<S.ButtonLogout onClick={props.logoutClicked}>Logout</S.ButtonLogout>
				{props.userRole==='admin' &&
					<NavLink 
						to={"/users-admin"} 
						style={{
							color:"white", 
							textDecoration: "unset", 
							position: "absolute", 
							right: '10px', 
							bottom:'10px', 
							zIndex:2}}
						> 
							<SettingsIcon/>
					</NavLink>
				}
			</React.Fragment>
		}
		{(props.location.pathname !== "/home" && width > 800) &&
			<S.Toolbar open={toolbarOpen}>
				<div>
					<button onClick={()=>setToolbarOpen(!toolbarOpen)}>{toolbarOpen
						? 
						<Tooltip title="Close Menu">
							<CloseIcon/>
						</Tooltip>
						: 
						<Tooltip title="Expand Menu">
							<OpenIcon/>
						</Tooltip>
					}</button>
					{toolbarOpen ? <Logo/> : null}
				</div>
				<S.navigationItems open={toolbarOpen}>
					<NavigationItems 
						clicked={()=>setToolbarOpen(false)}
						toolbarOpen={toolbarOpen}
						isAuthenticated={props.isAuth} 
						userRole={props.userRole}
					/>
				</S.navigationItems>
				<S.controlItems open={toolbarOpen}>
					{props.userRole==='admin' &&
						<S.Button open={toolbarOpen}> 
							<NavLink to="/users-admin" style={{color: `${colours.grey05}`, textDecoration: "unset"}}>
							{toolbarOpen 
								? 'Settings' 
								: 
								<Tooltip title="Settings">
									<SettingsIcon/>
								</Tooltip>
							} 
							</NavLink>
						</S.Button>
					}
					<S.Button color="red" onClick={props.logoutClicked} open={toolbarOpen}> 
						{toolbarOpen 
							? 'Logout'
							: 
							<Tooltip title="Logout">
								<ExitToAppIcon/>
							</Tooltip>
						}
					</S.Button>
				</S.controlItems>
			</S.Toolbar>
		}	
		{(props.location.pathname !== "/home" && width < 800) &&
			<React.Fragment>
				<header>
					<DrawerToggle clicked={props.drawerToggleClicked}/>
					<S.ButtonLogout onClick={props.logoutClicked} open={toolbarOpen}>Logout</S.ButtonLogout>
				</header>
				<NavLink to={"/users-admin"} style={{color:`${colours.white01}`, textDecoration: "unset", position: "absolute", right: '10px', bottom:'10px', zIndex:2}}> <SettingsIcon/></NavLink>
			</React.Fragment>
		}
		</React.Fragment>
    );
};

export default withRouter(Toolbar);