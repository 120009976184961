import * as actionTypes from './actionTypes';
import axios from "../../axios-instance.js";
// import axiosaws from "../../axios-instance-aws";
import { showNotification } from './../../utils/notification';

export const pieceDrafted = (id, user) => {
	return {
		type: actionTypes.PIECE_DRAFTED,
		payload: {
			piece: id,
			userRole: user
		}
	};
};

export const fetchPiecesStart = () => {
	return {
		type: actionTypes.FETCH_PIECES_START
	};
};

export const setPieces = ( pieces, secondary ) => {
	return {
		type: actionTypes.FETCH_PIECES,
		payload: {
			primary: pieces,
			secondary: secondary
		}
	};
};

export const fetchPiecesFailed = () => {
	return {
		type: actionTypes.FETCH_PIECES_FAILED
	}; 
};

export const clearDraftList = () => {
	return {
		type: actionTypes.CLEAR_DRAFT_LIST
	};

};

export const fetchPieceStart = () => {
	return {
		type: actionTypes.FETCH_PIECE_START
	};
};

export const fetchPieceSuccess = (data) => {
	return {
		type: actionTypes.FETCH_PIECE_SUCCESS,
		payload: data
	};
};

export const fetchPieceFail = (error) => {
	return {
		type: actionTypes.FETCH_PIECE_FAIL,
		payload: error
	};
};

export const fetchPiece = (id) => {
	return dispatch => {
		dispatch(fetchPieceStart());
		axios.get(`/pieces/${id}`)
		.then(response=>{
			if (response.status >= 200 && response.status <300) {
				dispatch(fetchPieceSuccess(response.data));
			};
		})
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(fetchPieceFail(error.response.data.errors));
				showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
			}
		});
 	};
};

export const clearSelectedArtwork = () => {
	return {
		type: actionTypes.CLEAR_SELECTED_ARTWORK
	}
};

export const editArtworkSuccess = () => {
	return {
		type: actionTypes.EDIT_ARTWORK_SUCCESS
	}
}
export const editArtworkFail = (error) => {
	return {
		type: actionTypes.EDIT_ARTWORK_FAIL,
		payload: error
	}
}
export const editArtworkStart = () => {
	return {
		type: actionTypes.EDIT_ARTWORK_START
	}
};

export const editArtwork = (id, formData, formDataSummary) => {
	return dispatch => {
		dispatch( editArtworkStart());
		axios.patch(`pieces/${id}`, formData)
			.then(response => {
				if (response.status >= 200 && response.status  < 300 ) {
					dispatch(editArtworkSummary(id, formDataSummary, formData["piece_name"]));
				};
			} )
			.catch( error => {
				if(error.response.status > 300 ){
					dispatch(editArtworkFail(error.response.data.errors));
					showNotification(`Oops, something went wrong! error: ${error.response.data.errors[0]}`,'error');
				}
			})
	}
}

export const editArtworkSummaryStart =()=> {
	return {
		type: actionTypes.EDIT_ARTWORK_NOTES_START
	};
};
export const editArtworkSummarySuccess =()=> {
	return {
		type: actionTypes.EDIT_ARTWORK_NOTES_SUCCESS
	};
};
export const editArtworkSummaryFail =(error)=> {
	return {
		type: actionTypes.EDIT_ARTWORK_NOTES_FAIL,
		payload: error
	};
};
export const editArtworkSummary = (id, summary, name) => {
	return dispatch => {
		dispatch( editArtworkSummaryStart());
		axios.patch( `pieces/${id}/piecesummaries/${id}`, summary)
			.then( response => {
				if (response.status >=200 && response.status < 300) {
					Promise.all([dispatch( editArtworkSummarySuccess()), dispatch(initPieces()), dispatch(fetchPiece(id))])
					.then(showNotification(`Artwork ${name} successfully updated.`));
				};
			})
			.catch ( error => {
				if(error.response.status > 300 ){
					dispatch(editArtworkSummaryFail(error.response.data.errors));
					showNotification(`Error in updating artwork ${name}: ${error.response.data.errors[0]}.`, 'error');
				}
			});
	};
};

export const editArtworkNotesStart =()=> {
	return {
		type: actionTypes.EDIT_ARTWORK_NOTES_START
	};
};
export const editArtworkNotesSuccess =()=> {
	return {
		type: actionTypes.EDIT_ARTWORK_NOTES_SUCCESS
	};
};
export const editArtworkNotesFail =(error)=> {
	return {
		type: actionTypes.EDIT_ARTWORK_NOTES_FAIL,
		payload: error
	};
};
export const editArtworkNotes = (id, notes, name) => {
	return dispatch => {
		dispatch( editArtworkNotesStart());
		axios.patch( `pieces/${id}/piecesummaries/${id}`, {notes: notes} )
			.then( response => {
				if (response.status >=200 && response.status < 300) {
					Promise.all([dispatch( editArtworkNotesSuccess()),dispatch(initPieces())])
					.then(showNotification(`Artwork ${name} successfully updated.`));
				};
			})
			.catch ( error => {
				if(error.response.status > 300 ){
					dispatch(editArtworkNotesFail(error.response.data.errors));
					showNotification(`Error in updating artwork ${name}: ${error.response.data.errors[0]}.`, 'error');
				}
			});
	};
};

export const handleAvailabilityStart = () => {
	return {
		type: actionTypes.PIECE_UNAVAILABLE_START,
	};
};

export const handleAvailabilitySuccess = () => {
	return {
		type: actionTypes.PIECE_UNAVAILABLE_SUCCESS
	};
};

export const handleAvailabilityFail = (error) => {
	return {
		type: actionTypes.PIECE_UNAVAILABLE_FAIL,
		payload: error
	};
};

export const makeUnavailability = (id, item) => {
	return dispatch => {
		dispatch( handleAvailabilityStart() );
		axios.patch(`pieces/${id}/piecesummaries/${id}`, { status: 'Not available'} )
		.then(response => {
			if (response.status >=200 && response.status < 300) {
				Promise.all([dispatch(handleAvailabilitySuccess()),dispatch( initPieces() )])
				.then(showNotification(`Artwork ${item.piece_name}-${item.identification} switched to Unavailable.`));
			};
		} )
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(handleAvailabilityFail(error.response.data.errors));
				showNotification(`Status up[dated failed, error: ${error.response.data.errors[0]}.`, 'error');
			}
		})
	}
};

export const removePieceFromDraftStart = (id) => {
	return {
		type: actionTypes.REMOVE_ITEM_DRAFT,
		payload: id
	}
}

export const removePieceFromDraftSuccess = () => {
	return {
		type: actionTypes.REMOVE_ITEM_DRAFT_SUCCESS
	}
}

export const removePieceFromDraftFail = (error) => {
	return {
		type: actionTypes.REMOVE_ITEM_DRAFT_FAIL,
		payload: error
	}
}

export const makeAvailability = (id, item) => {
	return dispatch => {
		dispatch( handleAvailabilityStart() );
		axios.patch(`pieces/${id}/piecesummaries/${id}`, { status: 'Available'} )
		.then(response => {
			if (response.status >= 200 && response.status <300){
				Promise.all([dispatch(handleAvailabilitySuccess()),dispatch( initPieces() )])
				.then(showNotification(`Artwork ${item.piece_name}-${item.identification} switched to Available.`));
			};
		} )
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(handleAvailabilityFail(error.response.data.errors));
				showNotification(`Status updated failed, error: ${error.response.data.errors[0]}.`, 'error');
			}
		})
	}
};

export const clearAwsData = () =>{
	return {
		type: actionTypes.CLEAR_AWS_STATE
	}
}

export const deleteArtworkStart = () => {
	return {
		type: actionTypes.PIECE_DELETED_START,
	};
};
export const deleteArtworkFail = (error) => {
	return {
		type: actionTypes.PIECE_DELETED_FAIL,
		payload: error
	};
};
export const deleteArtworkSuccess = () => {
	return {
		type: actionTypes.PIECE_DELETED_SUCCESS,
	};
};

export const deleteArtwork = (id) => {
	return dispatch => {
		dispatch(deleteArtworkStart());
		axios.delete(`/pieces/${id}`)
		.then(response => {
			if (response.status >= 200 && response.status <300){
				Promise.all([dispatch(deleteArtworkSuccess()),dispatch(initPieces())]);
				showNotification(`Artwork deleted.`);
			}
		})
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(deleteArtworkFail(error.response.data.errors));
				showNotification(`Artwork deletion error.`, 'error');
			}
		});
	}
}

export const initPieces = () => {
	return dispatch => {
		dispatch( fetchPiecesStart() );
		axios.get('/pieces')
		.then(response => {
			if (response.status >= 200 && response.status < 300) {
				const primary = response.data.filter(item => item.location_creation !== "Murano");
				const secondaryLocation = response.data.filter(item => item.location_creation === "Murano");
				dispatch( setPieces(primary, secondaryLocation) );
			};
		} )
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch( fetchPiecesFailed(error.response.data.errors) );
				showNotification(`Oops, something went wrong! `,'error');
			}
		} );
	};
};

export const fetchArtworkDetailsStart = () => {
	return {
		type: actionTypes.FETCH_ARTWORKS_DETAILS_START
	};
};
export const fetchArtworkDetailsSuccess = (data) => {
	return {
		type: actionTypes.FETCH_ARTWORKS_DETAILS_SUCCESS,
		payload: data
	};
};
export const fetchArtworkDetailsFail = (error) => {
	return {
		type: actionTypes.FETCH_ARTWORKS_DETAILS_FAIL,
		payload: error
	};
};

export const fetchArtworkEvents = (id) => {
	return dispatch => {
		dispatch(fetchArtworkDetailsStart());
		axios.get(`/pieces/${id}/events`)
		.then( response => {
			if (response.status >= 200 && response.status<300){
				dispatch(fetchArtworkDetailsSuccess(response.data))
			}
		})
		.catch( error => {
			if(error.response.status > 300 ){
				dispatch(fetchArtworkDetailsFail(error.response.data.errors));
				showNotification(`Oops, something went wrong. Error: ${error.response.data.errors[0]}`);
			}
		});
	};
}