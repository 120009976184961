import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
	artworks:{
		artworks: [],
		secondaryLocation: [],
		loading:  false,
		selected: [],
		isFetching: false,
		error: null
	},
	lists:{
		lists: [],
		secondaryLocation: [],
		loading: false,
		selected: [],
		isFetching: false,
		error: null
	},
	consignments:{
		consignments:[],
		secondaryLocation: [],
		loading: false,
		selected:[],
		isFetching: false,
		error: null
	},
	galleries:{
		galleries:[],
		secondaryLocation: [],
		loading: false,
		selected: [],
		isFetching: false,
		error: null
	},
	clients:{
		clients: [],
		secondaryLocation: [],
		loading: false,
		selected:[],
		isFetching: false,
		error: null
	},
	transactions:{
		transactions:[],
		secondaryLocation: [],
		loading:false,
		selected:[],
		isFetching: false,
		error: null
	},
	exhibitions:{
		exhibitions: [],
		secondaryLocation: [],
		loading: false,
		selected: [],
		isFetching: false,
		error: null
	},
	loading: false,
	error: null,
	currentGallery: null,
	listGallery: null,
	tabs: [],
}

const newExhibitionCreated = (state, action) =>{
	return updateObject(state, {
		exhibitions:{
			exhibitions: state.exhibitions.exhibitions,
			secondaryLocation: state.exhibitions.secondaryLocation,
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		}
	} );
};

const editArtworkNotesStart = (state, action) => {
	return updateObject( state, {
		artworks: {
			artworks: state.artworks.artworks,
			secondaryLocation: state.artworks.secondaryLocation,
			loading: true,
			selected: state.artworks.selected,
			isFetching: false,
			error: null
		}
	});
};

const editArtworkNotesSuccess = (state, action) => {
	return updateObject( state, {
		artworks: {
			artworks: state.artworks.artworks,
			secondaryLocation: state.artworks.secondaryLocation,
			loading: false,
			selected: state.artworks.selected,
			isFetching: false,
			error: null
		}
	});
};

const editArtworkNotesFail = (state, action) => {
	return updateObject( state, {
		artworks: {
			artworks: state.artworks.artworks,
			secondaryLocation: state.artworks.secondaryLocation,
			loading: false,
			selected: state.artworks.selected,
			isFetching: false,
			error: action.payload
		}
	});
};

const addPieceToDraft = ( state, action ) => {
	let allowedDrafted = {};
	let findPieceById = null;
	if (state.artworks.selected.length === 0) {
		action.payload.userRole === 'admin'
			? findPieceById = state.artworks.artworks.filter(artwork => artwork.id === action.payload.piece)
			: findPieceById = state.artworks.secondaryLocation.filter(artwork => artwork.id === action.payload.piece);
	} else {
		const filtered = state.artworks.selected.filter(item => item.id === action.payload.piece);
		if (filtered.length > 1) {
			allowedDrafted = {};
		} else {
			const id = action.payload.piece;
			allowedDrafted = { id };
		}
		action.payload.userRole === 'admin'
			? findPieceById = state.artworks.artworks.filter(artwork => artwork.id === allowedDrafted.id)
			: findPieceById = state.artworks.secondaryLocation.filter(artwork => artwork.id === allowedDrafted.id)
	}	
	
	const updatedProvList = [...state.artworks.selected, findPieceById[0]]
	const updatedState = {
		artworks: state.artworks.artworks,
		secondaryLocation: state.artworks.secondaryLocation,
		loading: false,
		selected: updatedProvList,
		isFetching: false,
		error:null
	};
	return updateObject( state, {artworks: updatedState} );
};

const clearDraftList = (state, action ) => {
	return updateObject( state, {
		artworks: {
			artworks: state.artworks.artworks,
			secondaryLocation: state.artworks.secondaryLocation,
			loading: false,
			selected: [],
			isFetching: false,
			error:null
		}
	});
};

const removePieceFromDraft = (state, action) => {
	const updatedProvList = state.artworks.selected.filter(item => item.id !== action.payload)
	const updatedState = {
		artworks: {
			artworks: state.artworks.artworks,
			secondaryLocation: state.artworks.secondaryLocation,
			loading: false,
			isFetching: false,
			selected: updatedProvList,
			error:null
		}
	}
	return updateObject( state, updatedState )
};

const piecesInList = (state, action) => {
	const pieces = action.payload.pieces;
	const findList = action.payload.location === 'murano' ? state.lists && state.lists.secondaryLocation.filter(x => x.id === action.payload.id) : state.lists && state.lists.lists.filter(x => x.id === action.payload.id);
	const list = {code: findList[0].list_name, gallery: findList[0].name};

	return updateObject(state,{
		lists:{
			lists: state.lists.lists,
			secondaryLocation: state.lists.secondaryLocation,
			selected: {pieces, list},
			error: null,
			isFetching: false,
			loading: false
		}
	})
}

const removePieceFromList =(state, action) => {
	const updatedProvList = state.lists.selected.pieces.filter( item => item.id !== action.payload)
	const list = state.lists.selected.list;
	
	return updateObject(state, {
		lists:{
			lists: state.lists.lists,
			secondaryLocation: state.lists.secondaryLocation,
			selected: {updatedProvList, list},
			error: null,
			isFetching: false,
			loading: false
		}
	});
};

const removePieceFromConsign =(state, action) => {
	const updatedProvConsign = state.consignments.selected.pieces.filter( item => item.id !== action.payload)
	const consign = state.consignments.selected.list;
	return updateObject(state, {
		consignments:{
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			error: null,
			isFetching: false,
			selected: {pieces: updatedProvConsign, list: consign },
			loading: false
		}	
	});
};

const piecesInConsign = (state, action ) => {
	const findConsign = action.payload.location === 'murano' ? state.consignments.secondaryLocation.filter(x => x.id === action.payload.id) : state.consignments.consignments.filter(x=> x.id === action.payload.id);

	return updateObject(state, {
		consignments:{
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			error: null,
			isFetching: false,
			selected: {pieces: action.payload.pieces, list:{code: findConsign[0].code, gallery: findConsign[0].name}},
			loading: false
		}
	});
};

const registerConsignmentStart = ( state, action ) => {
	return updateObject( state, { 
		consignments:{
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			error: null,
			isFetching: false,
			selected: state.consignments.selected,
			loading: true
		}
	} );
};

const registerConsignmentSuccess = (state, action ) => {
	return updateObject( state, {
		consignments:{
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			error: null,
			isFetching: false,
			selected: state.consignments.selected,
			loading: false
		}	
	} );
};

const registerConsignmentFail = (state, action) => {
	return updateObject( state, {
		consignments:{
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			error: action.payload,
			isFetching: false,
			selected: state.consignments.selected,
			loading: false
		}
	} );
};

const registerGalleryStart = ( state, action ) => {
	return updateObject( state, {
		galleries:{
			galleries: state.galleries.galleries,
			error: null,
			isFetching: false,
			selected: state.galleries.selected,
			loading: true
		}
	 } );
};

const registerGallerySuccess = (state, action ) => {
	return updateObject( state, {
		galleries:{
			galleries: state.galleries.galleries,
			error: null,
			isFetching: false,
			selected: state.galleries.selected,
			loading: false
		}	
	} );
};

const registerGalleryFail = (state, action) => {
	return updateObject( state, {
		galleries:{
			galleries: state.galleries.galleries,
			error: action.payload,
			isFetching: false,
			selected: state.galleries.selected,
			loading: false
		}
	} );
};

const createListStart = (state, action) => {
	return updateObject( state, { 
		lists:{
			lists: state.lists.lists,
			secondaryLocation: state.lists.secondaryLocation,
			selected: state.lists.selected,
			error: null,
			isFetching: false,
			loading: true
		} 
	});
};

const createListSuccess = (state, action) => {
	return updateObject( state, { 
		lists:{
			lists: state.lists.lists,
			secondaryLocation: state.lists.secondaryLocation,
			selected: [],
			error: null,
			isFetching: false,
			loading: false
		}, 
		artworks:  {
			artworks: state.artworks.artworks,
			secondaryLocation: state.artworks.secondaryLocation,
			error: null,
			selected: [],
			isFetching: false,
			loading: false
		}
	});
};

const createListFail = (state,action) => {
	return updateObject( state, { 
		lists:{
			lists: state.lists.lists,
			secondaryLocation: state.lists.secondaryLocation,
			selected: [],
			isFetching: false,
			error: action.payload,
			loading: false
		}  
	});
};

const fetchPieces = (state, action) => {
	return updateObject(state, {
		artworks:{
			artworks: action.payload.primary,
			secondaryLocation: action.payload.secondary,
			loading: false,
			isFetching: false,
			error: null,
			selected: state.artworks.selected
		}
	});
};

const fetchPiecesFailed= (state,action) => {
	return updateObject(state, {
		artworks:{
			artworks: state.artworks.artworks,
			secondaryLocation: state.artworks.secondaryLocation,
			loading: false,
			isFetching: false,
			error: action.error,
			selected: state.artworks.selected
		}
	});
};

const fetchPiecesStart = (state, action) => {
	return updateObject(state, {
		artworks:{
			artworks: [],
			secondaryLocation: [],
			loading: true,
			isFetching: true,
			error: null,
			selected: []
		} 
	});
};

const fetchConsignments = (state, action) => {
	return updateObject(state, {
		consignments:{
			consignments: action.payload.primary,
			secondaryLocation: action.payload.secondary,
			loading: false,
			isFetching: false,
			error: null,
			selected: state.consignments.selected
		} 
	});
};

const fetchConsignmentsStart = (state, action)=> {
	return updateObject(state, {
		consignments:{
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: true,
			isFetching: true,
			error: null,
			selected: state.consignments.selected
		}
	});
};

const fetchConsignmentsFailed= (state,action) => {
	return updateObject(state, { 
		consignments:{
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: false,
			isFetching: false,
			error: action.payload,
			selected: state.consignments.selected
		} 
	});
};

const fetchLists = (state, action) => {
	return updateObject(state, {
		lists:{
			lists: action.payload.primary,
			secondaryLocation: action.payload.secondary,
			selected: state.lists.selected,
			error: null,
			isFetching: false,
			loading: false
		} 
	});
};

const fetchListsStart = (state,action)=> {
	return updateObject(state, {
		lists:{
			lists: state.lists.lists,
			secondaryLocation: state.lists.secondaryLocation,
			selected: state.lists.selected,
			error: null,
			isFetching: true,
			loading: true
		} 
	});
};

const fetchListsFailed= (state,action) => {
	return updateObject(state, {
		lists:{
			lists: state.lists.lists,
			secondaryLocation: state.lists.secondaryLocation,
			selected: state.lists.selected,
			error: action.payload,
			isFetching: false,
			loading: false
		} 
	});
};

const fetchGalleries = (state, action) => {
	return updateObject(state, {
		galleries:{
			galleries: action.payload,
			selected: state.galleries.selected,
			error: null,
			isFetching: false,
			loading: false
		} 
	});
};
const fetchGalleriesStart = (state, action) => {
	return updateObject(state, {
		galleries:{
			galleries: state.galleries.galleries,
			selected: state.galleries.selected,
			error: null,
			isFetching: true,
			loading: true
		} 
	});
};

const fetchGalleriesFailed= (state,action) => {
	return updateObject(state, {
		galleries:{
			galleries: state.galleries.galleries,
			selected: state.galleries.selected,
			error: action.payload,
			isFetching: false,
			loading: false
		} 
	});
};

const clearState = (state, action) => {
	return updateObject(state, {
		artworks:{
			artworks: [],
			secondaryLocation: [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		}, 
		lists:{
			lists: [],
			secondaryLocation: [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		}, 
		consignments:{
			consignments: [],
			secondaryLocation: [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		}, 
		galleries:{
			galleries: [],
			secondaryLocation: [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		},
		clients:{
			clients: [],
			secondaryLocation: [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		},
		exhibitions:{
			exhibitions: [],
			secondaryLocation: [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		},
		transactions:{
			transactions: [],
			secondaryLocation: [],
			loading: false,
			error: null,
			selected: [],
			isFetching: false,
		}
	}) ;
};

const fetchExhibitions = (state, action) => {
	return updateObject(state, {
		exhibitions:{
			exhibitions: action.payload.primary,
			secondaryLocation: action.payload.secondary,
			loading: false,
			isFetching: false,
			error: null,
			selected: state.exhibitions.selected
		}
	});
};

const fetchExhibitionsStart = (state, actions) => {
	return updateObject(state, {
		exhibitions:{
			exhibitions: state.exhibitions.exhibitions,
			secondaryLocation: state.exhibitions.secondaryLocation,
			loading: true,
			isFetching: true,
			error: null,
			selected: state.exhibitions.selected
		}
	})
}
const fetchExhibitionsFailed= (state,action) => {
	return updateObject(state, {
		exhibitions:{
			exhibitions: [],
			secondaryLocation: state.exhibitions.secondaryLocation,
			loading: false,
			isFetching: false,
			error: action.payload,
			selected: state.exhibitions.selected
		}
	});
};

const selectedExhibitionData = (state,action) => {
	return updateObject(state, {
		exhibitions:{
			exhibitions: state.exhibitions.exhibitions,
			secondaryLocation: state.exhibitions.secondaryLocation,
			loading: false,
			isFetching: false,
			error: null,
			selected: action.payload
		}
	})
};

const saveAsConsignStart = (state, action) => {
	return updateObject(state, {
		consignments: {
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: true,
			selected: [],
			isFetching: false,
			error:null
		}
	} );
};

const saveAsConsignSuccess= (state, action)  =>{
	return updateObject(state, {
		consignments: {
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: false,
			selected: [],
			isFetching: false,
			error:null
		},
		artworks: {
			artworks: state.artworks.artworks,
			secondaryLocation: state.artworks.secondaryLocation,
			loading: false,
			selected: [],
			isFetching: false,
			error:null
		} 
	});
};

const saveAsConsignFail = (state, action) =>{
	return updateObject(state, {
		consignments: {
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: false,
			selected: [],
			isFetching: false,
			error: action.payload
		}
	});
};

const getGalleryArtworksStart = (state, action) => {
	return updateObject(state, {
		galleries: {
			galleries: state.galleries.galleries,
			loading: true,
			isFetching: false,
			selected: [],
			error:null
		} 
	});
};

const getGalleryArtworksSuccess = (state, action) => {
	return updateObject(state, {
		galleries: {
			galleries: state.galleries.galleries,
			loading: false,
			isFetching: false,
			selected: action.payload,
			error:null
		}
	});
};

const getGalleryArtworksFail = (state, action) => {
	return updateObject(state, {
		galleries: {
			galleries: state.galleries.galleries,
			loading: false,
			selected: [],
			isFetching: false,
			error:action.payload
		}
	} );
};

const pushToConsignStart = (state, action) => {
	return updateObject(state, {
		consignments: {
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: true,
			selected: [],
			isFetching: false,
			error:null
		},
	});
};

const pushToConsignSuccess = (state, action) => {
	return updateObject(state, {
		consignments: {
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: false,
			selected: [],
			isFetching: false,
			error:null
		}
	});
};

const pushToConsignFail = (state, action) => {
	return updateObject(state, {
		consignments: {
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: false,
			selected: [],
			isFetching: false,
			error: action.payload
		}
	});
};

const mergeToConsignStart = (state, action) => {
	return updateObject(state, { 
		consignments: {
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: true,
			selected: [],
			isFetching: false,
			error: null
		}
	});
};

const mergeToConsignSuccess = (state, action) => {
	return updateObject(state, {
		consignments: {
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: false,
			selected: [],
			isFetching: false,
			error: null
		}
	});
};

const mergeToConsignFail = (state, action) => {
	return updateObject(state, {
		consignments: {
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: false,
			selected: [],
			isFetching: false,
			error: action.payload
		}
	});
};

const clearSelectedState = (state,action)=>{
	return updateObject(state, {
		artworks:{
			artworks: state.artworks.artworks || [],
			secondaryLocation: state.artworks.secondaryLocation || [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		}, 
		lists:{
			lists: state.lists.lists || [],
			secondaryLocation: state.lists.secondaryLocation || [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		}, 
		consignments:{
			consignments: state.consignments.consignments || [],
			secondaryLocation: state.consignments.secondaryLocation || [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		}, 
		galleries:{
			galleries: state.galleries.galleries || [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		},
		clients:{
			clients: state.clients.clients || [],
			secondaryLocation: state.clients.secondaryLocation || [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		},
		exhibitions:{
			exhibitions: state.exhibitions.exhibitions || [],
			secondaryLocation: state.exhibitions.secondaryLocation || [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		},
		transactions:{
			transactions: state.transactions.transactions || [],
			secondaryLocation: state.transactions.secondaryLocation || [],
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		}
	})
};

// sales
const fetchClientsStart = (state, action) => {
	return updateObject(state, {
		clients:{
			clients: state.clients.clients,
			secondaryLocation: state.clients.secondaryLocation,
			loading: true,
			isFetching: true,
			error: null,
			selected: state.clients.selected
		}
	})
}
const fetchClientsSuccess = (state,action)=> {
	return updateObject(state, {
		clients:{
			clients: action.payload.primary,
			secondaryLocation: action.payload.secondary,
			loading: false,
			isFetching: false,
			error: null,
			selected: state.clients.selected
		}
	})
};
const fetchClientsFail = (state,action)=> {
	return updateObject(state, {
		clients:{
			clients: state.clients.clients,
			secondaryLocation: state.clients.secondaryLocation,
			loading: false,
			isFetching: false,
			error: action.payload,
			selected: state.clients.selected
		}
	})
};

const fetchClientSalesSuccess = (state, action) => {
	const transactions = action.payload.transactions;
	const client = {name: action.payload.first_name, surname: action.payload.last_name};
	const filteredArtworks = [];
	if (state.artworks.artworks){
		transactions.map(x=>{
			const sold = state.artworks.artworks.filter(artwork => artwork.id === x.piece_id)
			return filteredArtworks.push(sold[0])
		})
	}
	
	return updateObject(state, {
		clients:{
			clients: state.clients.clients,
			secondaryLocation: state.clients.secondaryLocation,
			loading: false,
			isFetching: false,
			error: null,
			selected: {client, filteredArtworks}
		} 
	})
};
const fetchClientSalesFail = (state, action) => {
	return updateObject(state, {
		clients:{
			clients: state.clients.clients,
			secondaryLocation: state.clients.secondaryLocation,
			loading: false,
			isFetching: false,
			error: action.payload,
			selected: []
		}
	})
}; 

const createTransactionFail = (state, action) => {
	return updateObject(state, {
		transactions:{
			transactions: state.transactions.transactions,
			secondaryLocation: state.transactions.secondaryLocation,
			loading: false,
			isFetching: false,
			error: action.payload,
			selected: []
		}
	})
};

const initTransactionsStart = (state, action)=>{
	return updateObject(state, {
		transactions:{
			transactions: state.transactions.transactions,
			secondaryLocation: state.transactions.secondaryLocation,
			loading: true,
			isFetching: true,
			error: null,
			selected: state.transactions.selected
		}
	})
};

const initTransactionsSuccess = (state, action)=> {
	return updateObject(state, {
		transactions:{
			transactions: action.payload.primary,
			secondaryLocation: action.payload.secondary,
			loading: false,
			error: null,
			isFetching:false,
			selected: state.transactions.selected
		}
	})
};

const initTransactionsFail = (state, action)=> {
	return updateObject(state, {
		transactions:{
			transactions: state.transactions.transactions,
			secondaryLocation: state.transactions.secondaryLocation,
			loading: false,
			error: action.payload,
			isFetching: false,
			selected: state.transactions.selected
		}
	})
};

const editTransactionStart = (state, action) => {
	return updateObject(state, {
		transactions:{
			transactions: state.transactions.transactions,
			secondaryLocation: state.transactions.secondaryLocation,
			loading: true,
			error: null,
			isFetching: false,
			selected: state.transactions.selected
		}
	});
};

const editTransactionSuccess =(state, action)=>{
	return updateObject(state, {
		transactions:{
			transactions: state.transactions.transactions,
			secondaryLocation: state.transactions.secondaryLocation,
			loading: false,
			error: null,
			isFetching: false,
			selected: state.transactions.selected
		}
	})
}
const editTransactionFail=(state, action) => {
	return updateObject(state, {
		transactions:{
			transactions: state.transactions.transactions,
			secondaryLocation: state.transactions.secondaryLocation,
			loading: false,
			error: action.payload,
			isFetching: false,
			selected: state.transactions.selected
		}
	})
}

const selectTransaction = (state, action) => {
	let allowedSelected = {};
	let findTransactionById = null;
	if (state.transactions.selected.length === 0) {
		findTransactionById = state.transactions.transactions.filter(transaction => transaction.id === action.payload);
	} else {
		const filtered = state.transactions.selected.filter(item => item.id === action.payload);
			if (filtered.length > 1) {
				allowedSelected = {};
			} else {
				const id = action.payload;
				allowedSelected = {id};
			}	
		findTransactionById = state.transactions.transactions.filter(transaction => transaction.id === allowedSelected.id);
	}	
	
	const updatedProvList = [...state.transactions.selected, findTransactionById[0]]

	return updateObject(state, {
		transactions:{
			transactions: state.transactions.transactions,
			secondaryLocation: state.transactions.secondaryLocation,
			loading: false,
			error: null,
			isFetching: false,
			selected: updatedProvList
		}
	} )
};

const removeTransaction = (state, action) => {
	const updatedProvList = state.transactions.selected.filter(item => item.id !== action.payload)

	return updateObject( state, {
		transactions:{
			transactions: state.transactions.transactions,
			secondaryLocation: state.transactions.secondaryLocation,
			loading: false,
			error: null,
			isFetching: false,
			selected: updatedProvList
		}
	})
};

const clearSelectedTransactions =(state, action)=> {
	return updateObject(state, {
		transactions:{
			transactions: state.transactions.transactions,
			secondaryLocation: state.transactions.secondaryLocation,
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		}
	})
};

const editConsignStart = (state, action) => {
	return updateObject(state, {
		consignments:{
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: true,
			error: null,
			isFetching: false,
			selected: []
		}
	});
};

const editConsignSuccess = (state, action) => {
	return updateObject(state, {
		consignments:{
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: false,
			error: null,
			isFetching: false,
			selected: []
		}
	});
};

const editConsignFail = (state, action) => {
	return updateObject(state, {
		consignments:{
			consignments: state.consignments.consignments,
			secondaryLocation: state.consignments.secondaryLocation,
			loading: false,
			error: action.payload,
			isFetching: false,
			selected: []
		}
	})
};

const reducer = (state = initialState, action) => {
	switch(action.type){
		case actionTypes.FETCH_PIECES:
			return fetchPieces(state,action);	
		case actionTypes.FETCH_PIECES_FAILED:
			return fetchPiecesFailed(state,action);	
		case actionTypes.FETCH_PIECES_START:
			return fetchPiecesStart(state,action);
		case actionTypes.FETCH_EXHIBITIONS_SUCCESS:
			return fetchExhibitions(state,action);	
		case actionTypes.FETCH_EXHIBITIONS_START:
			return fetchExhibitionsStart(state,action);	
		case actionTypes.FETCH_EXHIBITIONS_FAIL:
			return fetchExhibitionsFailed(state,action);
		case actionTypes.FETCH_CONSIGNMENTS_START:
			return fetchConsignmentsStart(state, action);
		case actionTypes.FETCH_CONSIGNMENTS:
			return fetchConsignments(state,action);
		case actionTypes.FETCH_CONSIGNMENTS_FAILED:
			return fetchConsignmentsFailed(state,action);		
		case actionTypes.FETCH_LISTS:
			return fetchLists(state,action);
		case actionTypes.FETCH_LISTS_START:
			return fetchListsStart(state, action);
		case actionTypes.FETCH_LISTS_FAILED:
			return fetchListsFailed(state,action);
		case actionTypes.FETCH_GALLERIES:
			return fetchGalleries(state,action);
		case actionTypes.FETCH_GALLERIES_START:
			return fetchGalleriesStart(state,action);
		case actionTypes.FETCH_GALLERIES_FAILED:
			return fetchGalleriesFailed(state,action);
		case actionTypes.PIECE_DRAFTED: 
			return addPieceToDraft( state, action );
		case actionTypes.REMOVE_ITEM_DRAFT:
			return removePieceFromDraft( state, action );
		case actionTypes.FOCUSED_LIST:
			return piecesInList(state,action);
		case actionTypes.FOCUSED_CONSIGNMENT:
			return piecesInConsign(state,action);
		case actionTypes.REGISTER_CONSIGNMENT_SUCCESS:
			return registerConsignmentSuccess(state,action);
		case actionTypes.REGISTER_CONSIGNMENT_FAIL:
			return registerConsignmentFail(state, action );
		case actionTypes.REGISTER_CONSIGNMENT_START:
			return registerConsignmentStart( state, action );
		case actionTypes.REGISTER_GALLERY_SUCCESS:
			return registerGallerySuccess(state,action);
		case actionTypes.REGISTER_GALLERY_FAIL:
			return registerGalleryFail(state, action );
		case actionTypes.REGISTER_GALLERY_START:
			return registerGalleryStart( state, action );
		case actionTypes.CREATE_LIST_START:
			return createListStart( state, action );
		case actionTypes.CREATE_LIST_SUCCESS:
			return createListSuccess( state, action );
		case actionTypes.CREATE_LIST_FAIL:
			return createListFail( state, action );	
		case actionTypes.SAVE_AS_LIST_START:
			return createListStart( state, action );
		case actionTypes.SAVE_AS_LIST_SUCCESS:
			return createListSuccess( state, action );
		case actionTypes.SAVE_AS_LIST_FAIL:
			return createListFail( state, action );	
		case actionTypes.CLEAR_DRAFT_LIST:
			return clearDraftList( state, action );
		case actionTypes.REMOVE_ITEM_LIST_SUCCESS:
			return removePieceFromList( state, action );
		case actionTypes.REMOVE_ITEM_CONSIGN_SUCCESS:
			return removePieceFromConsign( state, action );
		case actionTypes.SAVE_TO_LIST_SUCCESS:
			return clearDraftList(state,action);
		case actionTypes.AUTH_LOGOUT:
			return clearState(state,action);
		case actionTypes.SELECTED_EXHIBITION_DATA:
			return selectedExhibitionData(state,action);
		case actionTypes.NEW_EXHIBITION_SUCCESS:
			return newExhibitionCreated(state,action);
		case actionTypes.EDIT_ARTWORK_NOTES_START:
			return editArtworkNotesStart(state,action);
		case actionTypes.EDIT_ARTWORK_NOTES_SUCCESS:
			return editArtworkNotesSuccess(state,action);
		case actionTypes.EDIT_ARTWORK_NOTES_FAIL:
			return editArtworkNotesFail(state,action);
		case actionTypes.SAVE_AS_CONSIGN_START:
			return saveAsConsignStart(state,action);
		case actionTypes.SAVE_AS_CONSIGN_SUCCESS:
			return saveAsConsignSuccess(state,action);
		case actionTypes.SAVE_AS_CONSIGN_FAIL:
			return saveAsConsignFail(state,action);
		case actionTypes.FETCH_GALLERY_ARTWORKS_START:
			return getGalleryArtworksStart(state,action);
		case actionTypes.FETCH_GALLERY_ARTWORKS_SUCCESS:
			return getGalleryArtworksSuccess(state,action);
		case actionTypes.FETCH_GALLERY_ARTWORKS_FAIL:
			return getGalleryArtworksFail(state,action);
		case actionTypes.PUSH_TO_CONSIGN_START:
			return pushToConsignStart(state,action);
		case actionTypes.PUSH_TO_CONSIGN_SUCCESS:
			return pushToConsignSuccess(state,action);
		case actionTypes.PUSH_TO_CONSIGN_FAIL:
			return pushToConsignFail(state,action);
		case actionTypes.MERGE_TO_CONSIGN_START:
			return mergeToConsignStart(state,action);
		case actionTypes.MERGE_TO_CONSIGN_SUCCESS:
			return mergeToConsignSuccess(state,action);
		case actionTypes.MERGE_TO_CONSIGN_FAIL:
			return mergeToConsignFail(state,action);
		case actionTypes.CLEAR_SELECTED_LIST:
			return clearSelectedState(state, action);
		case actionTypes.FETCH_CLIENTS_START:
			return fetchClientsStart(state, action);
		case actionTypes.FETCH_CLIENTS_SUCCESS:
			return fetchClientsSuccess(state, action);
		case actionTypes.FETCH_CLIENTS_FAIL:
			return fetchClientsFail(state, action);
		case actionTypes.FETCH_CLIENTS_PIECES_SUCCESS:
			return fetchClientSalesSuccess(state, action);
		case actionTypes.FETCH_CLIENTS_PIECES_FAIL:
			return fetchClientSalesFail(state, action);
		case actionTypes.CREATE_TRANSACTION_FAIL:
			return createTransactionFail(state, action)
		case actionTypes.FETCH_TRANSACTIONS_START:
			return initTransactionsStart(state, action);
		case actionTypes.FETCH_TRANSACTIONS_SUCCESS:
			return initTransactionsSuccess(state, action);
		case actionTypes.FETCH_TRANSACTIONS_FAIL:
			return initTransactionsFail(state, action);
		case actionTypes.EDIT_TRANSACTION_START:
			return editTransactionStart(state, action);
		case actionTypes.EDIT_TRANSACTION_SUCCESS:
			return editTransactionSuccess(state, action);
		case actionTypes.EDIT_TRANSACTION_FAIL:
			return editTransactionFail(state, action);
		case actionTypes.SELECT_TRANSACTION:
			return selectTransaction(state, action);
		case actionTypes.REMOVE_TRANSACTION:
			return removeTransaction(state,action);
		case  actionTypes.CLEAR_SELECTED_TRANSACTIONS:
			return clearSelectedTransactions(state, action);
		case actionTypes.EDIT_CONSIGN_START:
			return editConsignStart(state, action);
		case actionTypes.EDIT_CONSIGN_SUCCESS:
			return editConsignSuccess(state,action);
		case actionTypes.EDIT_CONSIGN_FAIL:
			return editConsignFail(state,action);
		default:
			return state;	
	}
};

export default reducer;