import React from 'react';
import * as S from './style';
import NavigationItems from '../NavigationItems/NavigationItems';
import Backdrop from '../../UI/Backdrop/Backdrop';
import Aux from '../../../hoc/Aux/Aux';

const sideDrawer = ( props ) => {
    return (
        <Aux>
            <Backdrop show={props.open} clicked={props.closed}/>
            <S.SideDrawer open={props.open}>
                <S.Logo>QRator Admin</S.Logo>
                <nav>
                    <NavigationItems 
                        clicked={props.closed} 
                        isAuthenticated={props.isAuth} 
                        logoutClicked={props.logoutClicked} 
                        userRole={props.userRole}
                        toolbarOpen={true}
                    />
                </nav>
            </S.SideDrawer>
        </Aux>
    );
};

export default sideDrawer;