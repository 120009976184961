import React from 'react';
import Notification from 'rc-notification';
import 'rc-notification/assets/index.css';
import NotificationMessage from './../components/UI/Notification';


export function showNotification(message, type) {
  if (type === 'error') {
    Notification.newInstance(
      {style:{top: 'unset', bottom: 10, right: 10}},
      notification => {
        notification.notice({
          content: <NotificationMessage message={message} error />,
          closable: true,
          maxCount: 1,
          duration: 4,
          style: {
            padding: 0,
            backgroundColor: 'transparent',
          },
        });
      },
    );
  } else {
    Notification.newInstance(
      {style:{top: 'unset', bottom: 10, right: 10}},
      notification => {
        notification.notice({
          content: <NotificationMessage message={message} />,
          duration: 3,
          maxCount: 1,
          closable:true,
          style: {
              padding: 0,
              backgroundColor: 'transparent',  
          },
        });
    });
  }
}
